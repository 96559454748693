import axios from "axios";

async function getSite(siteId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/site/${siteId}`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function updateSite(siteId, data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/site/${siteId}`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function integrateShopifySite(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/shopify/integrate/site`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function adminSearch(query) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: query,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/account/log-as/search`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getSiteAgency(agencyId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/agency/sites?agencyId=${agencyId}`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function addClient(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/agency/site`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function deleteClient(clientId) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/agency/site/${clientId}`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getIntegrationUrlGoogle(siteId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/google/integration?siteId=${siteId}`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

/*
{
  apiKey: Joi.string().required(),
  status: Joi.string().valid(...enums.Integration.status).required(),
  type: Joi.string().valid(...enums.Integration.types).required(),
}
*/
async function integrateKlaviyo(siteId, data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/site/${siteId}/tools/integration`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function integrateInstagram(siteId, data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/site/${siteId}/tools/integration`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getIntegrations(siteId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/site/${siteId}/tools/integration`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    console.log(err);
    return [];
  }
}

async function getIntegration(siteId, type) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/site/${siteId}/tools/integration/${type}`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

async function deleteIntegration(siteId, _id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/site/${siteId}/tools/integration/${_id}`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
}

async function refreshCache(siteId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    //To change to proper URL
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/site/${siteId}/refresh-cache-data`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getProductUniqueValues(siteId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/site/${siteId}/get-product-unique-values`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export default {
  getSite,
  integrateShopifySite,
  updateSite,
  adminSearch,
  getSiteAgency,
  addClient,
  getIntegrationUrlGoogle,
  integrateKlaviyo,
  getIntegrations,
  getIntegration,
  refreshCache,
  deleteClient,
  integrateInstagram,
  deleteIntegration,
  getProductUniqueValues,
};
