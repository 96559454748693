<template>
  <div>
    <div v-if="isNotCurrentFeed" class="video-card">
      <tippy
        arrow="true"
        placement="top"
        a11y="false"
        boundary="window"
        interactive="true"
      >
        <template #trigger>
          <div v-if="isMainGrouping" class="stripe">
            <div class="stripe-content">
              <h2 class="thumbnail-title">{{ formattedThumbnailGrouping }}</h2>
            </div>
          </div>
          <div
            class="title"
            :class="{ 'not-from-current-feed-title': isNotCurrentFeed }"
          >
            <div class="index">
              <img
                class="drag-drop-icon"
                :class="{ 'disabled-icon-button': isNotCurrentFeed }"
                src="@/assets/drag-drop-icon.png"
              />
              <div class="index-input">
                <input
                  v-model="inputIndex"
                  class="video-index-input"
                  type="number"
                  :min="1"
                  :max="videos.length"
                  @change="onIndexChange"
                />
              </div>
            </div>
            <div class="icon-buttons">
              <button
                class="button-primary-small icon-button"
                :class="{ 'disabled-icon-button': isNotCurrentFeed }"
                :disabled="isNotCurrentFeed"
                @click="emitReplaceVideo"
              >
                <UpdateVideoIcon
                  v-tippy="{
                    placement: 'top',
                    arrow: true,
                    boundary: 'window',
                  }"
                  class="slide-icon"
                  :content="$t('create.step3.replaceVideo')"
                />
              </button>
              <button
                v-if="isCarousel && isFullScreen"
                class="button-primary-small icon-button"
                :class="{ 'disabled-icon-button': isNotCurrentFeed }"
                :disabled="isNotCurrentFeed"
                @click="emitGroupThumbnail"
              >
                <ThumbnailGroupingIcon
                  v-tippy="{
                    placement: 'top',
                    arrow: true,
                    boundary: 'window',
                  }"
                  class="slide-icon"
                  :content="groupThumbnailTippy"
                />
              </button>
              <button
                class="button-primary-small icon-button"
                :class="{ 'disabled-icon-button': isNotCurrentFeed }"
                :disabled="isNotCurrentFeed"
                @click="emitDelete"
              >
                <DeleteSmall
                  v-tippy="{
                    placement: 'top',
                    arrow: true,
                    boundary: 'window',
                  }"
                  class="trash-icon"
                  :content="$t('create.step3.removeVideo')"
                />
              </button>
            </div>
          </div>

          <div
            class="content"
            :class="{
              'bulk-content': isBulk,
              'not-from-current-feed-content': isNotCurrentFeed,
            }"
            @click="emitClick"
          >
            <div class="imgs">
              <div>
                <div
                  class="main-img-container"
                  :class="{ 'bulk-main-img-container': isBulk }"
                  :style="`background-image: url('${img}');`"
                ></div>
                <p class="video-name">{{ video.fileName || video.name }}</p>
              </div>
              <img src="@/assets/arrow-next-simple.png" alt="arrow" />
            </div>
            <p class="video-element" :class="{ 'bulk-video-element': isBulk }">
              {{ $t("create.step3.element") }}
              <span class="video-element-type">
                <CartIcon v-if="cta && cta.ctaType === 3" class="normal-icon" />
                <OpenIcon v-if="cta && cta.ctaType === 0" class="normal-icon" />
                <CopyIcon v-if="cta && cta.ctaType === 1" class="normal-icon" />
                <EmailIcon
                  v-if="cta && cta.ctaType === 2"
                  class="email-icon"
                />{{
                  cta && cta.ctaType > -1
                    ? $t("create.step3.buttonSelector.buttonOptions").find(
                        (e) => e.value == cta.ctaType
                      )?.label
                    : "N/A"
                }}</span
              >
            </p>
          </div>
        </template>
        <div v-if="isNotCurrentFeed" class="multi-feed-tooltip">
          <p>{{ $t("create.step3.multiFeedTooltip.text") }}</p>
          <p>
            {{ $t("create.step3.multiFeedTooltip.feed")
            }}<span>"{{ video.feedName }}"</span>
          </p>
          <button class="button-primary" @click="goToBulkEdit">
            {{ $t("create.step3.multiFeedTooltip.button") }}
          </button>
        </div>
      </tippy>
    </div>
    <div v-else>
      <div class="video-card">
        <div v-if="isMainGrouping" class="stripe">
          <div class="stripe-content">
            <h2 class="thumbnail-title">{{ formattedThumbnailGrouping }}</h2>
          </div>
        </div>
        <div
          class="title"
          :class="{ 'not-from-current-feed-title': isNotCurrentFeed }"
        >
          <div class="index">
            <img class="drag-drop-icon" src="@/assets/drag-drop-icon.png" />
            <div class="index-input">
              <input
                v-model="inputIndex"
                class="video-index-input"
                type="number"
                :min="1"
                :max="videos.length"
                @change="onIndexChange"
              />
            </div>
          </div>
          <div class="icon-buttons">
            <button
              class="button-primary-small icon-button"
              :class="{ 'disabled-icon-button': isNotCurrentFeed }"
              :disabled="isNotCurrentFeed"
              @click="emitReplaceVideo"
            >
              <UpdateVideoIcon
                v-tippy="{ placement: 'top', arrow: true, boundary: 'window' }"
                class="slide-icon"
                :content="$t('create.step3.replaceVideo')"
              />
            </button>
            <button
              v-if="isCarousel && isFullScreen"
              class="button-primary-small icon-button"
              :class="{ 'disabled-icon-button': isNotCurrentFeed }"
              :disabled="isNotCurrentFeed"
              @click="emitGroupThumbnail"
            >
              <ThumbnailGroupingIcon
                v-tippy="{ placement: 'top', arrow: true, boundary: 'window' }"
                class="slide-icon"
                :content="groupThumbnailTippy"
              />
            </button>
            <button
              class="button-primary-small icon-button"
              :class="{ 'disabled-icon-button': isNotCurrentFeed }"
              :disabled="isNotCurrentFeed"
              @click="emitDelete"
            >
              <DeleteSmall
                v-tippy="{ placement: 'top', arrow: true, boundary: 'window' }"
                class="trash-icon"
                :content="$t('create.step3.removeVideo')"
              />
            </button>
          </div>
        </div>
        <div
          class="content"
          :class="{
            'bulk-content': isBulk,
            'not-from-current-feed-content': isNotCurrentFeed,
          }"
          @click="emitClick"
        >
          <div class="imgs">
            <div>
              <div
                class="main-img-container"
                :class="{ 'bulk-main-img-container': isBulk }"
                :style="`background-image: url('${img}');`"
              ></div>
              <p class="video-name">{{ video.fileName || video.name }}</p>
            </div>
            <img src="@/assets/arrow-next-simple.png" alt="arrow" />
          </div>
          <p class="video-element" :class="{ 'bulk-video-element': isBulk }">
            {{ $t("create.step3.element") }}
            <span class="video-element-type">
              <CartIcon v-if="cta && cta.ctaType === 3" class="normal-icon" />
              <OpenIcon v-if="cta && cta.ctaType === 0" class="normal-icon" />
              <CopyIcon v-if="cta && cta.ctaType === 1" class="normal-icon" />
              <EmailIcon v-if="cta && cta.ctaType === 2" class="email-icon" />{{
                cta && cta.ctaType > -1
                  ? $t("create.step3.buttonSelector.buttonOptions").find(
                      (e) => e.value == cta.ctaType
                    )?.label
                  : "N/A"
              }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteSmall from "@/assets/svg/delete-small.svg?inline";
import CartIcon from "@/assets/svg/cta/cart-icon.svg?inline";
import OpenIcon from "@/assets/svg/cta/open-icon.svg?inline";
import CopyIcon from "@/assets/svg/cta/copy-icon.svg?inline";
import EmailIcon from "@/assets/svg/cta/email-icon.svg?inline";
import UpdateVideoIcon from "@/assets/svg/update-video-icon.svg?inline";
import ThumbnailGroupingIcon from "@/assets/svg/thumbnail-grouping-icon.svg?inline";

export default {
  name: "VideoCard",
  components: {
    DeleteSmall,
    CartIcon,
    OpenIcon,
    CopyIcon,
    EmailIcon,
    UpdateVideoIcon,
    ThumbnailGroupingIcon,
  },
  props: {
    video: { type: Object, default: () => {} },
    index: { type: Number, default: () => 0 },
    cta: { type: Object, default: () => {} },
    img: { type: String, default: "" },
    isPortrait: { type: Boolean, default: false },
    isBulk: { type: Boolean, default: false },
    isCarousel: { type: Boolean, default: false },
    isFullScreen: { type: Boolean, default: false },
    videos: { type: Array, default: () => [] },
    isMainGrouping: { type: Boolean, default: false },
    thumbnailGrouping: { type: String, default: "" },
    isNotCurrentFeed: { type: Boolean, default: false }
  },
  data() {
    return {
      inputIndex: this.index + 1,
    };
  },
  computed: {
    formattedThumbnailGrouping() {
      if (this.thumbnailGrouping) {
        return 'Thumbnail'
        return (
          this.thumbnailGrouping.charAt(0).toUpperCase() +
          this.thumbnailGrouping.slice(1)
        );
      }
      return "";
    },
    groupThumbnailTippy() {
      if (this.isMainGrouping) {
        return this.$t("create.step3.thumbnailGrouping.disableTooltip");
      } else return this.$t("create.step3.thumbnailGrouping.setTooltip");
    },
  },
  watch: {
    index(newIndex) {
      this.inputIndex = newIndex + 1;
    },
    videos: {
      handler() {
        this.inputIndex = this.index + 1;
      },
      deep: true,
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
    emitDelete() {
      this.$emit("delete", this.index);
    },
    emitGroupThumbnail() {
      if (this.isMainGrouping) {
        this.$emit("disable-thumbnail", this.video);
      } else {
        this.$emit("group-thumbnail", this.video);
      }
    },
    onIndexChange() {
      const newIndex = this.inputIndex - 1;
      if (
        newIndex !== this.index &&
        newIndex >= 0 &&
        newIndex < this.videos.length
      ) {
        this.$emit("change-index", { oldIndex: this.index, newIndex });
      } else {
        this.inputIndex = this.index + 1;
      }
    },
    emitReplaceVideo() {
      this.$emit("replace-video", this.index, this.video);
    },
    goToBulkEdit() {
      this.$router.push({
        name: "editBulkEmbed",
        params: { campaignId: this.video.campaignId },
        query: { feedIndex: this.video.feedIndex, isEditMultipleFeed: true },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.video-card {
  cursor: pointer;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0.5px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  width: 90%;
  margin: 10px auto;
  overflow: hidden;
  &:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  }
  &:hover .title {
    opacity: 1;
  }

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background: $dark-purple;
    border-radius: 4px 4px 0px 0px;
    color: #ffffff;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    .index {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px 16px;
      h3 {
        font-family: "Yeon Sung";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
      }
    }
    button {
      border-radius: 0px;
      padding: 5px 10px;
      border-radius: 3px;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
      margin: 12px;
    }
  }

  .content {
    padding: 15px;
    word-break: break-word;
  }
  .bulk-content {
    padding: 12px;
    width: unset;
  }

  .imgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-img-container {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: black;
    height: 100px;
    width: 200px;
    text-align: center;
  }
  .bulk-main-img-container {
    height: 90px;
    width: 180px;
  }
  .main-img-premade {
    height: 100px;
    width: 100px;
  }
  .main-img-portrait {
    height: 100px;
  }

  .main-img {
    img,
    div {
      height: 100px;
      width: 200px;
    }
  }
  .main-img-container p,
  .main-img p {
    padding: 10px 0;
    text-align: left;
  }

  .video-name {
    margin-top: 10px;
    color: #c4c4c4;
    @include font-smaller;
    margin-bottom: 10px;
  }
  .video-element {
    display: flex;
    gap: 12px;
    span {
      font-weight: $w-normal;
    }
  }
  .bulk-video-element {
    font-size: 14px;
  }
  .video-element-type {
    display: flex;
    gap: 8px;
    align-items: center;
    color: $dark-purple;
    .normal-icon {
      path {
        fill: $dark-purple;
      }
    }
    .email-icon {
      path {
        stroke: $dark-purple;
      }
    }
  }
}
.icon-buttons {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 8px 16px;
}
.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  padding: 0 !important;
  margin: 0 !important;
}

.icon-button svg {
  outline: none;
}
.trash-icon {
  transform: scale(150%);
  path {
    fill: $dark-grey;
    fill-opacity: 1;
  }
}
.slide-icon {
  transform: scale(115%);
}

.stripe {
  width: 500px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  position: absolute;
  top: 200px;
  left: -150px;
  background-color: $dark-purple;
}

.stripe div {
  height: 24px;
  text-align: center;
}

.thumbnail-title {
  @include base-font;
  @include font-smallest;
  font-weight: 600;
  line-height: 24px;
  color: white;
}

.video-index-input {
  font-family: "Yeon Sung";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  background-color: transparent;
  outline: none;
  border: none;
  color: white;
}
.icon-button {
  &:active {
    background: $dark-grey;
    svg {
      path {
        fill: white;
      }
    }
  }
}
.not-from-current-feed-content {
  background: rgba(243, 243, 243, 1);
}

.not-from-current-feed-title {
  background: rgba(88, 88, 88, 1) !important;
}
button.disabled-icon-button {
  cursor: not-allowed;
}
img.disabled-icon-button {
  cursor: not-allowed;
}
.multi-feed-tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 10px;
  p {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
  }
  span {
    font-weight: 700;
  }
}
</style>
