<template>
  <small-modal
    :title="$t('closeSegmentationModal.title')"
    class="delete-campaign-modal"
    :fit-content="true"
    @close="close"
  >
    <div class="delete-campaign-modal-container">
      <h3 class="subtitle">{{ $t("closeSegmentationModal.subtitle") }}</h3>
      <banner-modal
        banner-type="warning"
        :title="$t('deleteCampaign.warning')"
        :text="$t('closeSegmentationModal.banner')"
      >
      </banner-modal>
      <div class="buttons">
        <input
          class="button-primary --grey"
          type="button"
          :value="$t('deleteCampaign.cancel')"
          @click.prevent="close"
        />
        <label class="button-primary button-flex">
          <input
            class="submit-button"
            type="submit"
            :value="$t('closeSegmentationModal.button')"
            @click="confirm"
          />
        </label>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "../shared/SmallModal";
import BannerModal from "../shared/BannerModal.vue";

export default {
  components: {
    SmallModal,
    BannerModal,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-campaign-modal-container {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.subtitle {
  text-align: center;
  @include font-normal;
}

.delete-icon {
  transform: scale(175%);
}

/*save and back button*/
.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 24px;
}

.button-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: 2px 2px 15px 0px rgba(235, 0, 0, 0.25);
  &:hover {
    background-color: rgba(235, 0, 0, 0.25);
  }
}

.submit-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  background: none;
  border: none;
}

@include media(">phone", "<=tablet") {
  .card-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include font-normal;
  }

  .subtitle {
    @include font-small;
  }
}

@include media("<=phone") {
  .card-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include font-normal;
  }
}
</style>
