<template>
  <div
    v-click-outside="closeModal"
    class="modal"
    :class="{ 'is-advanced-modal': isAdvancedSegmentation }"
  >
    <ul class="data-type-list">
      <li @click="onDataTypeSelected('products')">
        {{ $t("advancedSegmentation.addProducts") }}
      </li>
      <li @click="onDataTypeSelected('url')">
        {{ $t("advancedSegmentation.addUrls") }}
      </li>
      <li @click="onDataTypeSelected('category')">
        {{ $t("advancedSegmentation.addCategories") }}
      </li>
      <li @click="onDataTypeSelected('brand')">
        {{ $t("advancedSegmentation.addBrands") }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    isAdvancedSegmentation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataType: null,
    };
  },
  methods: {
    onDataTypeSelected(dataType) {
      this.dataType = dataType;
      this.$emit("select", dataType);
      this.closeModal();
    },
    closeModal() {
      if (this.isAdvancedSegmentation && !this.dataType) {
        this.$emit("cancel");
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  bottom: -135px;
  background: white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(196, 196, 196, 1);
  min-width: 160px;
  padding: 4px 0;
  z-index: 100;
}

.data-type-list {
  list-style: none;
  padding: 0;
}

.data-type-list li {
  @include base-font;
  @include font-small;
  cursor: pointer;
  background-color: transparent;
  padding: 6px 8px;
}

.data-type-list li:hover {
  background-color: $light-pink;
}
</style>
