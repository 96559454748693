<template>
  <div
    v-if="isVisible"
    v-click-outside="closeModal"
    class="modal"
    :class="{
      'first-url-segmentation-modal': isFirstUrlSegmentation,
      'advanced-segmentation': isAdvancedSegmentation,
    }"
  >
    <div class="radio-group">
      <div
        v-if="isUrlInclusion || (!isUrlExclusion && !isUrlInclusion)"
        class="radio-option"
      >
        <input id="eq" v-model="filter.comparison" type="radio" value="eq" />
        <label for="eq"> {{ $t("advancedSegmentation.eq2") }} </label>
      </div>
      <div v-show="filter.comparison === 'eq'" class="input-group">
        <input
          v-model="filter.string"
          class="text-input"
          type="text"
          placeholder="Enter value"
          @input="filterOptions"
          @keyup.enter="closeModal"
        />
        <ul v-if="filteredOptions.length" class="list-wrapper">
          <li
            v-for="option in filteredOptions"
            :key="option"
            class="list-item"
            @click="selectOption(option)"
          >
            {{ option }}
          </li>
        </ul>
      </div>
      <div
        v-if="isUrlExclusion || (!isUrlInclusion && !isUrlExclusion)"
        class="radio-option"
      >
        <input
          id="not_eq"
          v-model="filter.comparison"
          type="radio"
          value="not_eq"
        />
        <label for="not_eq"> {{ $t("advancedSegmentation.not_eq2") }} </label>
      </div>
      <div v-show="filter.comparison === 'not_eq'" class="input-group">
        <input
          v-model="filter.string"
          class="text-input"
          type="text"
          placeholder="Enter value"
          @input="filterOptions"
          @keyup.enter="closeModal"
        />
        <ul v-if="filteredOptions.length" class="list-wrapper">
          <li
            v-for="option in filteredOptions"
            :key="option"
            class="list-item"
            @click="selectOption(option)"
          >
            {{ option }}
          </li>
        </ul>
      </div>
      <div
        v-if="isUrlInclusion || (!isUrlExclusion && !isUrlInclusion)"
        class="radio-option"
      >
        <input
          id="contains"
          v-model="filter.comparison"
          type="radio"
          value="contains"
        />
        <label for="contains">
          {{ $t("advancedSegmentation.contains") }}
        </label>
      </div>
      <div v-show="filter.comparison === 'contains'" class="input-group">
        <input
          v-model="filter.string"
          class="text-input"
          type="text"
          placeholder="Enter value"
          @input="filterOptions"
          @keyup.enter="closeModal"
        />
        <ul v-if="filteredOptions.length" class="list-wrapper">
          <li
            v-for="option in filteredOptions"
            :key="option"
            class="list-item"
            @click="selectOption(option)"
          >
            {{ option }}
          </li>
        </ul>
      </div>
      <div
        v-if="isUrlExclusion || (!isUrlInclusion && !isUrlExclusion)"
        class="radio-option"
      >
        <input
          id="not_contains"
          v-model="filter.comparison"
          type="radio"
          value="not_contains"
        />
        <label for="not_contains">
          {{ $t("advancedSegmentation.not_contains") }}
        </label>
      </div>
      <div v-show="filter.comparison === 'not_contains'" class="input-group">
        <input
          v-model="filter.string"
          class="text-input"
          type="text"
          placeholder="Enter value"
          @input="filterOptions"
          @keyup.enter="closeModal"
        />
        <ul v-if="filteredOptions.length" class="list-wrapper">
          <li
            v-for="option in filteredOptions"
            :key="option"
            class="list-item"
            @click="selectOption(option)"
          >
            {{ option }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { normalizeString } from "@/utils/stringNormalize";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    isFirstUrlSegmentation: {
      type: Boolean,
      default: false,
    },
    isNewAdvancedRule: {
      type: Boolean,
      default: false,
    },
    isAdvancedSegmentation: {
      type: Boolean,
      default: false,
    },
    firstFilter: {
      type: Object,
      default: null,
    },
    isUrlInclusion: {
      type: Boolean,
      default: false,
    },
    isUrlExclusion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: true,
      selectedOption: this.filter.comparison || "",
      filteredOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      getProductUniqueValues: "site/getProductUniqueValues",
      siteId: "account/getSiteId",
    }),
    prefilledOptions() {
      return this.getProductUniqueValues || {};
    },
  },
  watch: {
    selectedOption(newVal) {
      this.$emit("update-comparison", newVal);
    },
  },
  async created() {
    if (!this.getProductUniqueValues) {
      await this.fetchProductUniqueValues(this.siteId);
    }
  },
  methods: {
    ...mapActions({
      fetchProductUniqueValues: "site/getProductUniqueValues",
    }),
    closeModal() {
      this.isVisible = false;
      this.$emit("close");
    },
    filterOptions() {
      if (this.filter.string.length >= 3) {
        const type = this.filter.dataType;

        const normalizedFilterString = normalizeString(
          this.filter.string
        ).toLowerCase();

        if (type === "category" || type === "brand") {
          this.filteredOptions = this.prefilledOptions[type].filter((option) =>
            normalizeString(option)
              .toLowerCase()
              .startsWith(normalizedFilterString)
          );
        }
      } else {
        this.filteredOptions = [];
      }
    },
    selectOption(option) {
      this.filter.string = option;
      this.filteredOptions = [];
      this.$emit("update-filter", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  bottom: 32px;
  background: white;
  padding: 8px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid rgba(196, 196, 196, 1);
}
.first-url-segmentation-modal {
  bottom: 40px;
  left: 165px;
  z-index: 200;
}

.radio-group {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 12px;
}

.radio-group label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
}

.radio-option {
  display: flex;
  align-items: center;
}

.text-input {
  border: 1px solid rgba(196, 196, 196, 1);
  border-radius: 2px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
}
.advanced-segmentation {
  bottom: -165px;
  z-index: 100;
}

.list-wrapper {
  @include base-font;
  @include font-small;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-left: 8px;
}

.list-item {
  cursor: pointer;
  color: $light-grey;
}
</style>
