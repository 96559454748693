<template>
  <small-modal
    :fit-content="true"
    class="add-to-cart"
    @close="$emit('close-add-to-cart')"
  >
    <div class="modal-container">
      <div
        class="title-container"
        :class="{
          'advanced-segmentation-title-container': isAdvancedSegmentation,
        }"
      >
        <h1 class="modal-title">
          {{ $t(`create.step3.atcModal.plural`) }}
        </h1>
        <tippy
          v-if="!isShopify"
          arrow="true"
          placement="bottom"
          a11y="false"
          interactive="true"
        >
          <template #trigger>
            <QuestionIcon />
          </template>
          <div class="tippy-content">
            <p class="tooltip-text">
              {{ $t("create.step3.productsTippy.text") }}
            </p>
            <button
              class="button-primary tooltip-button"
              @click="goToProductsTab"
            >
              {{ $t("create.step3.productsTippy.button") }}
            </button>
          </div>
        </tippy>
        <div v-if="isAdvancedSegmentation">
          <label
            :class="[
              'comparison-radio',
              'include-radio',
              { selected: advancedSegmentationComparison === 'eq' },
            ]"
          >
            <input
              v-model="advancedSegmentationComparison"
              type="radio"
              value="eq"
            />
            {{ $t(`create.step3.include`) }}
          </label>
          <label
            :class="[
              'comparison-radio',
              'exclude-radio',
              { selected: advancedSegmentationComparison === 'not_eq' },
            ]"
          >
            <input
              v-model="advancedSegmentationComparison"
              type="radio"
              value="not_eq"
            />
            {{ $t(`create.step3.exclude`) }}
          </label>
        </div>
      </div>
      <!-- <h2 class="number-products">
        {{ selectedProducts.length }} products selected.
      </h2> -->
      <div class="search-bar-wrapper">
        <search-bar
          class="products-search-bar"
          :placeholder="searchBarPlaceholder"
          @query="filterListOfProducts"
        ></search-bar>
        <p class="products-result">
          {{ products.length }} {{ $t(`create.step3.results`) }}
        </p>
        <button class="select-all-button" @click="selectAllProducts">
          {{ $t(`create.step3.selectAll`) }}
        </button>
      </div>
      <div class="sorting">
        <div v-for="sortingOption in sortingOptions" :key="sortingOption.key">
          <div
            v-if="isShopify || sortingOption.key !== 'quantity'"
            class="sort-by"
          >
            <h3
              class="sort-option"
              :class="{ 'sort-option-selected': sortingOption.order !== '' }"
              @click="toggleSortingOrder(sortingOption.key)"
            >
              {{ sortingOption.label }}
            </h3>
            <div class="sorting-arrows">
              <SortArrow
                class="sort-arrow"
                :class="{
                  'arrow-selected': sortingOption.order === 'ascending',
                }"
                @click="toggleSortingOrder(sortingOption.key)"
              />
              <SortArrow
                class="sort-arrow reverse-arrow"
                :class="{
                  'arrow-selected': sortingOption.order === 'descending',
                }"
                @click="toggleSortingOrder(sortingOption.key)"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isShopify && hasNoProducts" class="no-products-section">
        <div class="no-products-instructions">
          <div class="no-products-image">
            <img src="@/assets/sitting-man.png" alt="sitting-man" />
          </div>
          <div class="no-products-text">
            <p>{{ $t("productPages.noProductsInstructions.1") }}</p>
            <p>{{ $t("productPages.noProductsInstructions.goodNews") }}</p>
          </div>
        </div>
        <button
          class="no-products-button action-button"
          @click="goToProductsTab"
        >
          {{ $t("productPages.noProductsInstructions.button") }}
        </button>
      </div>
      <form v-else class="product-form">
        <div ref="topForm" class="product-card-container">
          <div
            v-for="{
              imageUrl,
              name,
              variants,
              productUrl,
              _id,
            } in combinedProducts"
            :key="_id"
            class="input-container"
          >
            <label
              v-tippy="{
                arrow: true,
                placement: 'top',
                a11y: false,
                maxWidth: 200,
              }"
              :content="name"
              class="product-card"
              :class="{
                selected: selectedProducts.find(
                  (product) => product._id === _id
                ),
              }"
              name="product"
              :for="_id"
              @click="addProductToSelectedProduct(_id)"
            >
              <div class="img-container">
                <img
                  v-lazy="imageUrl"
                  alt="product-image"
                  class="product-image"
                />
              </div>
              <div class="product-info">
                <p class="product-name">
                  {{ name.length > 28 ? name.substring(0, 20) + "..." : name }}
                </p>
                <p class="product-data">{{ firstVariantPrice(variants) }}</p>
                <p class="product-data">{{ calculateTotalQty(variants) }}</p>
              </div>
              <a
                v-if="productUrl"
                class="new-tab"
                :href="productUrl"
                target="_blank"
                ><NewTabIcon
              /></a>
            </label>
          </div>
        </div>
      </form>
    </div>
    <div class="buttons-container">
      <p
        class="selected-products"
        :class="{ 'no-products-selected': selectedProducts.length === 0 }"
      >
        {{ selectedProducts.length }} {{ $t(`create.step3.selected`) }}
      </p>
      <div
        v-if="productsLoading"
        class="button-primary submit-button products-loading"
      >
        <p>{{ $t("create.step3.buttonSelector.productsLoading") }}</p>
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <button
        v-else
        class="button-primary button-flex product-button"
        @click="submitAddToCartProducts()"
      >
        {{ $t("shared.buttons.confirm") }}
        <ConfirmIcon />
      </button>
    </div>
  </small-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SmallModal from "@/components/shared/SmallModal.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import ConfirmIcon from "@/assets/svg/confirm-icon.svg?inline";
import NewTabIcon from "@/assets/svg/open_new_tab.svg?inline";
import QuestionIcon from "@/assets/svg/question-icon.svg?inline";
import { normalizeString } from "@/utils/stringNormalize";

export default {
  name: "AddToCartModal",
  components: {
    SmallModal,
    SearchBar,
    SortArrow,
    ConfirmIcon,
    NewTabIcon,
    QuestionIcon,
  },

  props: {
    selectedProductsEdit: {
      type: [Boolean, Array],
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    productsLoading: {
      type: Boolean,
      default: false,
    },
    isAdvancedSegmentation: {
      type: Boolean,
      default: false,
    },
    productsComparisonValue: {
      type: String,
      default: "eq",
    },
  },
  data() {
    return {
      products: [],
      completeListOfProducts: [],
      selectedProducts: [],
      sortingOptions: [
        {
          key: "names",
          label: this.$t(`create.step3.sortProducts.sortNames`),
          order: "",
        },
        {
          key: "prices",
          label: this.$t(`create.step3.sortProducts.sortPrices`),
          order: "",
        },
        {
          key: "quantity",
          label: this.$t(`create.step3.sortProducts.sortQuantity`),
          order: "",
        },
      ],
      sortOrder: {},
      advancedSegmentationComparison: "eq",
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      siteId: "account/getSiteId",
      currency: "site/getRawCurrency",
      getCurrency: "site/getCurrency",
    }),
    combinedProducts() {
      return this.selectedProducts.concat(
        this.products.filter((item) => this.selectedProducts.indexOf(item) < 0)
      );
    },
    isShopify() {
      return this.site.site.integration === "shopify";
    },
    hasNoProducts() {
      return this.products.length === 0;
    },
    searchBarPlaceholder() {
      return this.$t(`create.step3.placeholder`);
    },
  },

  async created() {
    this.products = await this.$store.dispatch("product/getProducts", {
      siteId: this.siteId,
      isProductListView: false,
    });
    if (this.selectedProductsEdit) {
      this.selectedProductsEdit.forEach((product) => {
        if (product && product._id) {
          this.addProductToSelectedProduct(product._id, true);
        }
      });
    }
    this.completeListOfProducts = this.products;
    if (this.isAdvancedSegmentation) {
      this.advancedSegmentationComparison =
        this.productsComparisonValue || "eq";
    }
  },

  methods: {
    addProductToSelectedProduct(productId, isInit = false) {
      // Find the selected product
      const selectedProduct = this.products.find(
        (product) => product._id === productId
      );

      // Check if the product is already selected
      const isSelected = this.selectedProducts.some(
        (product) => product._id === selectedProduct._id
      );

      if (isSelected) {
        // If the product is selected, deselect it
        const selectedIndex = this.selectedProducts.findIndex(
          (product) => product._id === selectedProduct._id
        );
        if (selectedIndex !== -1) {
          this.selectedProducts.splice(selectedIndex, 1);
        }
      } else {
        // If the product is not selected, add it to the beginning
        this.selectedProducts.unshift(selectedProduct);
      }

      // Scroll to the top form
      if (!isInit) this.$refs.topForm.scrollIntoView({ behavior: "smooth" });

      // Preserve the sorting order for the rest of the products
      const selectedSortingOption = this.sortingOptions.find(
        (option) => option.order !== ""
      );

      if (selectedSortingOption) {
        const sortingFunction = this.sortingType(
          selectedSortingOption.key,
          selectedSortingOption.order
        );

        // Sort only the remaining unselected products
        const unselectedProducts = this.products.filter(
          (product) =>
            !this.selectedProducts.some(
              (selected) => selected._id === product._id
            )
        );

        unselectedProducts.sort(sortingFunction);

        // Combine the selected and unselected products
        this.products = this.selectedProducts.concat(unselectedProducts);
      }
    },

    sortArrayAlphabetically(array) {
      return array.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },

    submitAddToCartProducts() {
      if (!this.selectedProducts || this.selectedProducts.length === 0) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.addProductNoDevice"),
          type: "error",
        });
        return;
      }
      this.selectedProducts.forEach((product) => {
        product.currency = this.currency;
      });
      if (this.isAdvancedSegmentation) {
        this.$emit(
          "products-to-advanced-segmentation",
          this.selectedProducts,
          this.advancedSegmentationComparison
        );
      } else {
        this.$emit("submit-add-to-cart-products", this.selectedProducts);
      }
    },
    filterListOfProducts(payload) {
      const normalizedPayload = normalizeString(payload).toLowerCase();
      this.products = this.completeListOfProducts.filter((product) => {
        return (
          normalizeString(product.name)
            .toLowerCase()
            .includes(normalizedPayload) ||
          product.productId?.toString().includes(payload)
        );
      });
    },
    sortByName(order) {
      return (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return order === "ascending" ? -1 : 1;
        }
        if (nameA > nameB) {
          return order === "ascending" ? 1 : -1;
        }
        return 0;
      };
    },
    sortByPrice(order) {
      return (a, b) => {
        const priceA = a.variants[0].price;
        const priceB = b.variants[0].price;
        return order === "ascending" ? priceA - priceB : priceB - priceA;
      };
    },

    sortByQuantity(order) {
      return (a, b) => {
        const qtyA = a.variants[0].qty;
        const qtyB = b.variants[0].qty;
        return order === "ascending" ? qtyA - qtyB : qtyB - qtyA;
      };
    },
    toggleSortingOrder(key) {
      const orderMap = {
        "": "ascending",
        ascending: "descending",
        descending: "",
      };

      this.sortingOptions.forEach((option) => {
        if (option.key === key) {
          option.order = orderMap[option.order];
        } else {
          option.order = "";
        }
      });
      if (
        key === "names" &&
        this.sortingOptions.find((option) => option.key === "names").order ===
          ""
      ) {
        this.sortArrayAlphabetically(this.products);
      } else {
        const selectedSortingOption = this.sortingOptions.find(
          (option) => option.key === key
        );
        const sortingFunction = this.sortingType(
          key,
          selectedSortingOption.order
        );
        this.combinedProducts.sort(sortingFunction);
      }
    },
    sortingType(key, order) {
      switch (key) {
        case "names":
          return this.sortByName(order);
        case "prices":
          return this.sortByPrice(order);
        case "quantity":
          return this.sortByQuantity(order);
        default:
          return null;
      }
    },
    calculateTotalQty(variants) {
      if (variants) {
        let totalQty = variants.reduce(
          (total, variant) => total + variant.qty,
          0
        );
        let hasUnlimited = variants.some((variant) => variant.sellWithoutStock);

        if (hasUnlimited) {
          return this.$t("create.bulk.pagesModal.stock.unlimited");
        } else if (totalQty === 0) {
          return this.$t("create.bulk.pagesModal.stock.outStock");
        } else if (!variants[0].qty) {
          return "";
        } else {
          return totalQty + " " + this.$t("create.bulk.pagesModal.stock.stock");
        }
      }

      return this.$t("create.bulk.pagesModal.stock.outStock");
    },
    firstVariantPrice(variants) {
      if (variants && variants.length > 0) {
        const firstVariant = variants[0];
        const price = firstVariant ? firstVariant.price : 0;

        if (this.getCurrency === "$") {
          return `$${price}`;
        } else if (this.getCurrency === "€") {
          return `${price}€`;
        } else {
          return `${price}${this.getCurrency}`;
        }
      } else {
        return "";
      }
    },
    goToProductsTab() {
      this.$router.push({ name: "productPages" });
    },
    selectAllProducts() {
      const allProducts = new Set([
        ...this.products,
        ...(Array.isArray(this.selectedProductsEdit)
          ? this.selectedProductsEdit
          : []),
      ]);

      this.selectedProducts = [...allProducts];
    },
  },
};
</script>

<style lang="scss">
.add-to-cart .small-modal.fit-modal {
  max-height: 550px;
}

.products-search-bar.search-bar {
  width: 65%;
  margin: 16px 0;
}
</style>

<style lang="scss" scoped>
.add-to-cart {
  z-index: 30;
  text-align: center;
}

.search-bar-wrapper {
  width: 480px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.products-result {
  @include base-font;
  @include font-small;
}

.select-all-button {
  @include base-font;
  @include font-small;
  background-color: transparent;
  color: $dark-purple;
  text-decoration: underline;
}

.modal-container {
  display: grid;
  grid-template-rows: repeat(4, auto) 1fr;
  width: 600px;
  height: 650px;
  margin: 0 auto;
  overflow: hidden;
}
.title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-bottom: 4px;
}
.modal-title {
  margin-bottom: 0;
}

.number-products {
  @include base-font;
  @include font-normal;
  font-weight: 400;
}

.product-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  width: 80%;
  margin: 0 auto;
}

.product-form::-webkit-scrollbar {
  width: 1px;
}
.product-form::-webkit-scrollbar-track {
  background: transparent;
}
.product-form::-webkit-scrollbar-thumb {
  background: transparent;
}

.product-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr) auto;
  grid-gap: 16px;
  margin-bottom: 16px;
}

.input-container {
  display: flex;
  justify-content: flex-start;
}

.product-card {
  height: 210px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: none;
  border-radius: 4px;
  position: relative;
  &:hover {
    background-color: rgba($light-pink, 0.6);
  }
}
.selected {
  background-color: $light-pink;
}
.sorting {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 80%;
  margin: 0 auto 8px;
}
.sort-option {
  &:hover {
    cursor: pointer;
  }
  &.sort-option-selected {
    color: $dark-purple;
  }
}
.sort-by {
  display: flex;
  align-items: center;
  gap: 12px;
}

.sorting-arrows {
  display: flex;
  flex-direction: column;
  gap: 4px;
  transform: scale(70%);
  .sort-arrow {
    &:hover {
      cursor: pointer;
    }
    path {
      fill: $light-grey;
    }
  }
  .reverse-arrow {
    transform: translateX(-0.9px);
    path {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }
  .arrow-selected {
    path {
      fill: $dark-purple;
    }
  }
}

.img-container {
  height: 100px;
  width: 100px;
  @include flex-centered;
  justify-content: flex-start;
  margin: 8px 0;
}

.new-tab {
  position: absolute;
  top: 8px;
  right: 8px;
}

.product-image {
  display: block;
  max-height: 100px;
  max-width: 100px;
  width: auto;
  height: auto;
  border-radius: 4px;
  margin-left: 8px;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  margin-left: 8px;
}

.product-name {
  @include font-normal;
  font-weight: 600;
  height: 32px;
  text-align: left;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-data {
  @include font-small;
  font-weight: 400;
}

.buttons-container {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  gap: 32px;
  margin: 8px auto;
  padding: 8px 0;
  width: 100%;
  background-color: white;
  box-shadow: inset 10px 0px 10px -10px rgba(0, 0, 0, 0.2),
    inset -10px 0px 10px -10px rgba(0, 0, 0, 0.2);
}
.button-flex {
  display: flex;
  align-items: center;
  gap: 8px;
}
.tippy-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 10px 12px;
}

.tooltip-text {
  @include base-font;
  @include font-small;
  font-weight: 500;
  color: white;
  text-align: start;
  cursor: default;
}
.tooltip-button {
  width: fit-content;
  @include font-small;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
}

.no-products-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 80%;
  margin: 24px auto 0;
  height: 300px;
  border-radius: 8px;
  background: rgba(226, 124, 252, 0.1);
}

.no-products-instructions {
  display: flex;
  gap: 16px;
  padding: 24px 24px 0;
}

.no-products-text {
  @include base-font;
  @include font-big;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
  gap: 24px;
  p {
    text-align: start;
  }
}
.no-products-button {
  width: 90%;
  margin: 0 auto;
}

.selected-products {
  @include base-font;
  @include font-small;
}

.no-products-selected {
  color: rgba(196, 196, 196, 1);
}

.products-loading {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: content-box;
  gap: 12px;
  white-space: nowrap;
}

.lds-ring {
  color: $dark-purple;
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
  right: 0;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.advanced-segmentation-title-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 auto;
}
.comparison-radio {
  @include base-font;
  @include font-smaller;
  font-weight: 400;
  padding: 6px 20px;
  border: 1px solid rgba(163, 163, 163, 1);
  color: rgba(163, 163, 163, 1);
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s;
  box-sizing: border-box;
  min-width: 60px;
}
.include-radio {
  border-radius: 3px 0 0 3px;
}
.exclude-radio {
  border-radius: 0 3px 3px 0;
}

.comparison-radio.selected {
  background-color: $dark-purple;
  color: white;
  border: 1px solid $dark-purple;
}
input[type="radio"] {
  display: none;
}
</style>
