<template>
  <small-modal :fit-content="true" @close="$emit('close')">
    <div class="confirmation-modal-content">
      <h1 class="confirmation-modal-title">
        {{ $t("create.step3.leaveModal.title") }}
      </h1>
      <h2 class="confirmation-modal-subtitle">
        {{ $t("create.step3.leaveModal.subtitle") }}
      </h2>
      <banner-modal
        banner-type="info"
        :title="$t('deleteCampaign.information')"
        :text="$t('create.step3.leaveModal.bannerInfo')"
      >
        <template #icon>
          <InfoIcon />
        </template>
      </banner-modal>
      <div class="confirmation-modal-buttons">
        <button class="button-primary" @click="$emit('close')">
          {{ $t("create.step3.leaveModal.cancel") }}
        </button>
        <button class="button-primary" @click="$emit('leave')">
          {{ $t("create.step3.leaveModal.confirm") }}
        </button>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal";
import BannerModal from "@/components/shared/BannerModal.vue";
import InfoIcon from "@/assets/svg/banner-info-icon.svg?inline";
export default {
  components: {
    SmallModal,
    BannerModal,
    InfoIcon,
  },
};
</script>

<style lang="scss" scoped>
.confirmation-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.confirmation-modal-title {
  @include base-font;
  @include font-big;
  font-weight: 600;
  margin-bottom: 14px;
}
.confirmation-modal-subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  margin-bottom: 24px;
  white-space: nowrap;
}
.confirmation-modal-buttons {
  margin-top: 36px;
  display: flex;
  gap: 85px;
}
</style>
