import { render, staticRenderFns } from "./BulkEmbed.vue?vue&type=template&id=cab2b486&scoped=true&"
import script from "./BulkEmbed.vue?vue&type=script&lang=js&"
export * from "./BulkEmbed.vue?vue&type=script&lang=js&"
import style0 from "./BulkEmbed.vue?vue&type=style&index=0&id=cab2b486&prod&lang=scss&"
import style1 from "./BulkEmbed.vue?vue&type=style&index=1&id=cab2b486&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cab2b486",
  null
  
)

export default component.exports