<template>
  <div class="edit-footer">
    <button
      :disabled="disabledPrev"
      :class="[
        'cancel-button flex-button',
        { 'small-font': isSmallFontOnMobile, disabled: disabledPrev },
      ]"
      @click="handleClickPrev"
    >
      {{ $t("create.bulk.modalButtons.leave") }}
      <SmallSquaredX class="button-icon" />
    </button>
    <button
      :disabled="disabledNext"
      :class="[
        'save-button flex-button',
        { 'small-font': isSmallFontOnMobile, disabled: disabledNext },
      ]"
      @click="handleClickNext"
    >
      {{ $t("create.bulk.modalButtons.save") }}
      <SquaredCheckIcon class="button-icon" />
    </button>
  </div>
</template>

<script>
import SquaredCheckIcon from "@/assets/svg/squared-check-icon.svg?inline";
import SmallSquaredX from "@/assets/svg/small-squared-x-icon.svg?inline";
export default {
  name: "EditFooter",
  components: {
    SquaredCheckIcon,
    SmallSquaredX,
  },
  props: {
    isSmallFontOnMobile: {
      type: Boolean,
      default: false,
    },
    disabledPrev: {
      type: Boolean,
      default: false,
    },
    disabledNext: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickPrev() {
      this.$emit("prev");
    },
    handleClickNext() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-footer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.disabled {
  cursor: not-allowed;
}

.flex-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

@include media("<tablet") {
  .small-font {
    @include font-smallest;
  }
}
</style>
