<template>
  <small-modal
    class="delete-tag-modal"
    :fit-content="true"
    @close="$emit('close-delete-tag-modal')"
  >
    <div class="delete-tag-modal-wrapper">
      <div class="title-container">
        <h1 class="title">
          {{ $t("campaignManager.tags.deleteTagModal.deleteTag") }}
        </h1>
        <h2 class="subtitle">
          {{ $t("campaignManager.tags.deleteTagModal.sureToDelete") }}
        </h2>
      </div>
      <div class="warning-wrapper">
        <WarningIcon class="warning-icon" />
        <div class="warning">
          <p class="warning-title">
            {{ $t("campaignManager.tags.deleteTagModal.warning") }}
          </p>
          <p class="warning-text">
            {{ $t("campaignManager.tags.deleteTagModal.instructions") }}
          </p>
        </div>
      </div>
      <div class="modal-action-buttons">
        <button
          class="submit-button back-button"
          @click="$emit('close-delete-tag-modal')"
        >
          {{ $t("campaignManager.tags.deleteTagModal.cancel") }}
        </button>
        <button class="submit-button delete-button" @click="deleteTag">
          {{ $t("campaignManager.tags.deleteTagModal.delete")
          }}<DeleteIcon class="delete-icon" />
        </button>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal.vue";
import WarningIcon from "@/assets/svg/settings/warning-icon-banner.svg?inline";
import DeleteIcon from "@/assets/svg/delete-small.svg?inline";
import { mapGetters } from "vuex";
export default {
  components: {
    SmallModal,
    WarningIcon,
    DeleteIcon,
  },
  props: {
    tagToDelete: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      siteId: "site/getSiteId",
    }),
  },
  methods: {
    async deleteTag() {
      await this.$store.dispatch("tag/deleteTag", this.tagToDelete._id);
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("campaignManager.tags.deleteTag"),
        type: "success",
      });
      this.$emit("close-delete-tag-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
$warning-red: rgba(235, 0, 0, 1);
$warning-background-red: rgba(235, 0, 0, 0.15);

.delete-tag-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  box-sizing: content-box;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include base-font;
  @include font-big;
  margin-bottom: 16px;
}

.subtitle {
  @include base-font;
  @include font-normal;
}

.warning-wrapper {
  width: 440px;
  min-height: 100px;
  background-color: $warning-background-red;
  border-radius: 2px;
  border-left: 3px solid $warning-red;
  position: relative;
}
.warning-icon {
  position: absolute;
  top: 15px;
  left: 8px;
  .path1 {
    fill: $warning-red;
  }
  .path2 {
    fill: $warning-red;
  }
  .path3 {
    fill: $warning-red;
  }
  .path4 {
    fill: $warning-red;
  }
}
.warning {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin: 16px 16px 0 40px;
}
.warning-title {
  @include font-normal;
  font-weight: 600;
  color: $warning-red;
}

.warning-text {
  color: $warning-red;
  @include font-normal;
}

.modal-action-buttons {
  display: flex;
  gap: 80px;
}

.submit-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  width: 115px;
  height: 36px;
  background: transparent;
  border-radius: 20px;
  box-shadow: 2px 2px 15px 0px $warning-background-red;
}
.back-button {
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.18);
  &:hover {
    background-color: rgba(0, 0, 0, 0.18);
  }
}
.delete-button {
  box-shadow: 2px 2px 15px 0px $warning-background-red;
  &:hover {
    background-color: $warning-background-red;
  }
}

.delete-icon {
  transform: scale(200%);
  margin-left: 12px;
  path {
    fill: $dark-grey;
    fill-opacity: 1;
  }
}
</style>
