<template>
  <div>
    <PagesSegmentationNav
      :selected-option="selectedOption"
      :number-of-products="segmentation?.productId?.length"
      :url-segmentation="segmentation?.url"
      :segmentation="segmentation"
      @optionSelected="handleOptionSelected"
    />
    <div v-if="selectedOption === 'advanced'">
      <AdvancedSegmentation
        :segmentation="segmentation"
        @update-conditions="updateAdvancedSegmentation"
      />
    </div>
    <div
      v-if="selectedOption === 'standard'"
      class="product-segmentation-wrapper"
    >
      <ProductSegmentation
        class="product-segmentation"
        :product-ids="segmentation?.productId"
        :url-segmentation="segmentation?.url"
        @update-product-segmentation="updateProductSegmentation"
        @update-url-segmentation="updateUrlSegmentation"
      />
    </div>
  </div>
</template>

<script>
import PagesSegmentationNav from "@/components/create-campaign-steps/PagesSegmentationNav.vue";
import ProductSegmentation from "@/components/create-campaign-steps/ProductSegmentation.vue";
import AdvancedSegmentation from "@/components/shared/AdvancedSegmentation.vue";

import { mapState, mapGetters } from "vuex";

export default {
  components: {
    PagesSegmentationNav,
    ProductSegmentation,
    AdvancedSegmentation,
  },
  props: {
    segmentation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedOption: "pageUrls",
    };
  },
  computed: {
    ...mapState({ site: "site" }),
    ...mapGetters({
      siteId: "account/getSiteId",
    }),
  },
  async created() {
    this.products = await this.$store.dispatch("product/getProducts", {
      siteId: this.siteId,
      isProductListView: false,
    });
  },
  methods: {
    updateUrlSegmentation(payload) {
      this.$emit("updateSegmentation", { ...this.segmentation, ...payload });
    },
    updateProductSegmentation(productIds) {
      this.$emit("updateSegmentation", {
        ...this.segmentation,
        productId: productIds,
      });
    },
    handleOptionSelected(option) {
      this.selectedOption = option;
      this.$emit("option-selected", option);
    },
    updateAdvancedSegmentation(payload) {
      this.$emit("update-advanced-segmentation", {
        ...this.segmentation,
        ...payload,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-segmentation-wrapper {
  position: relative;
}
.product-segmentation {
  margin-bottom: 24px;
}
</style>
