<template>
  <div
    class="bulk-buttons"
    :class="{
      'step3-bulk-button': isStep3,
      'step3-product-pages': isProductPages,
      'step3-bulk-format': isBulkFormat,
    }"
  >
    <button class="cancel-button flex-button" @click="$emit('cancel')">
      {{ $t("create.bulk.modalButtons.leave") }}
      <SmallSquaredX class="button-icon" />
    </button>
    <button class="save-button flex-button" @click="$emit('confirm')">
      {{ $t("create.bulk.modalButtons.save") }}
      <SquaredCheckIcon class="button-icon" />
    </button>
  </div>
</template>

<script>
import SquaredCheckIcon from "@/assets/svg/squared-check-icon.svg?inline";
import SmallSquaredX from "@/assets/svg/small-squared-x-icon.svg?inline";

export default {
  components: {
    SquaredCheckIcon,
    SmallSquaredX,
  },
  props: {
    isStep3: {
      type: Boolean,
      default: false,
    },
    isProductPages: {
      type: Boolean,
      default: false,
    },
    isBulkFormat: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.bulk-buttons {
  width: calc(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: absolute;
  bottom: 40px;
  left: 0;
  height: 56px;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.15));
}
.step3-bulk-button {
  width: 25%;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.15));
  bottom: 40px;
}

.step3-product-pages {
  bottom: 40px;
}

.flex-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.step3-bulk-format {
  background-color: transparent;
  height: unset;
  position: unset;
}
</style>
