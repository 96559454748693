<template>
  <div class="product-pages-component">
    <ProductPages
      :is-product-segmentation="true"
      :segmented-products="segmentedProducts"
      @remove-product-segmentation="removeProduct"
    />
    <div
      v-if="displayUrlRules || inclusionCondition || exclusionCondition"
      class="url-rules"
    >
      <div class="inclusion">
        <button
          v-if="!inclusionCondition"
          class="page-exclusion"
          @click="addInclusion"
        >
          {{ $t("urlSegmentation.inclusion") }}
        </button>
        <SegmentationConditions
          v-else
          :data-type="'URL'"
          :condition="inclusionCondition"
          :index="inclusionIndex"
          :is-url-segmentation="true"
          :is-url-inclusion="true"
          @updateCondition="updateInclusionCondition"
          @removeCondition="removeInclusionCondition"
        />
        <SegmentationFilterDropdown
          v-if="displayInclusionDropdown"
          class="inclusion-dropdown"
          :is-first-url-segmentation="true"
          :filter="filterForDropdown"
          :first-filter="firstFilter"
          :is-url-inclusion="isUrlInclusion"
          :is-url-exclusion="isUrlExclusion"
          @close="confirmRule"
        />
      </div>
      <button v-if="shouldShowLogicToggle()" class="logic-toggle">
        {{ globalOperator }}
      </button>
      <div class="exclusion">
        <button
          v-if="!exclusionCondition"
          class="page-exclusion"
          @click="addExclusion"
        >
          {{ $t("urlSegmentation.exclusion") }}
        </button>
        <SegmentationConditions
          v-else
          :data-type="'URL'"
          :condition="exclusionCondition"
          :index="exclusionIndex"
          :is-url-segmentation="true"
          :is-url-exclusion="true"
          @updateCondition="updateExclusionCondition"
          @removeCondition="removeExclusionCondition"
        />
        <SegmentationFilterDropdown
          v-if="displayExclusionDropdown"
          class="exclusion-dropdown"
          :is-first-url-segmentation="true"
          :filter="filterForDropdown"
          :first-filter="firstFilter"
          :is-url-inclusion="isUrlInclusion"
          :is-url-exclusion="isUrlExclusion"
          @close="confirmRule"
        />
      </div>
    </div>
    <div
      :class="[
        'buttons',
        { 'sticky-buttons': isSticky },
        { 'buttons-with-margin-top': displayUrlRules },
      ]"
    >
      <button class="add-product-button" @click="toggleProductsModal">
        <TagIcon />
        {{ $t("create.bulk.pagesModal.addProduct") }}
      </button>
      <button
        class="add-product-button add-url-button"
        @click="showAddUrlRules"
      >
        <LinkIcon class="link-icon" />{{ $t("urlSegmentation.mainButton") }}
      </button>
    </div>
    <div class="bottom-page"></div>
    <div class="scroll-here"></div>
    <AddToCartModal
      v-if="showProductsModal"
      :is-full-screen="true"
      :selected-products-edit="getSelectedProducts(productIds)"
      :products-loading="productsLoading"
      @close-add-to-cart="showProductsModal = false"
      @submit-add-to-cart-products="submitAddToCartProducts"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AddToCartModal from "@/components/create-campaign-steps/step3/AddToCartModal.vue";
import ProductPages from "@/components/ProductPages.vue";
import SegmentationConditions from "@/components/shared/SegmentationConditions.vue";
import TagIcon from "@/assets/svg/tag-icon.svg?inline";
import LinkIcon from "@/assets/svg/link-icon.svg?inline";
import SegmentationFilterDropdown from "@/components/shared/SegmentationFilterDropdown.vue";

export default {
  components: {
    AddToCartModal,
    ProductPages,
    TagIcon,
    LinkIcon,
    SegmentationConditions,
    SegmentationFilterDropdown,
  },
  props: {
    productIds: { type: Array, default: () => [] },
    urlSegmentation: { type: Array, default: () => [] },
  },
  data() {
    return {
      showProductsModal: false,
      productsLoading: false,
      conditions: [],
      globalOperator: "and",
      firstFilter: null,
      displayUrlRules: false,
      isUrlInclusion: false,
      isUrlExclusion: false,
      inclusionCondition: null,
      exclusionCondition: null,
      filterForDropdown: null,
      displayInclusionDropdown: false,
      displayExclusionDropdown: false,
      isSticky: false,
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.product.products,
    }),
    ...mapGetters({
      getCurrency: "site/getCurrency",
      isUsingProductKey: "site/isUsingProductKey",
      siteId: "account/getSiteId",
      currentFeedEdit: "campaign/currentFeedEdit",
      feedList: "campaign/feedList",
    }),
    segmentedProducts() {
      return this.products.filter((product) =>
        this.productIds.includes(product.productId)
      );
    },
    productListHeaders() {
      return [
        this.$t("create.bulk.pagesModal.listItems.productPages") +
          this.productIds.length,
        this.$t(""),
        this.$t(""),
        this.$t(""),
        this.$t("create.bulk.pagesModal.listItems.inventory"),
        this.$t("create.bulk.pagesModal.listItems.price"),
      ];
    },
    lastCondition() {
      return this.conditions[this.conditions.length - 1] || {};
    },
    newInclusionCondition() {
      return (
        this.conditions.find(
          (condition) =>
            condition.filters[0]?.comparison === "eq" ||
            condition.filters[0]?.comparison === "contains"
        ) || null
      );
    },
    newExclusionCondition() {
      return (
        this.conditions.find(
          (condition) =>
            condition.filters[0]?.comparison === "not_eq" ||
            condition.filters[0]?.comparison === "not_contains"
        ) || null
      );
    },
    inclusionIndex() {
      return this.conditions.findIndex(
        (condition) =>
          condition.filters[0]?.comparison === "eq" ||
          condition.filters[0]?.comparison === "contains"
      );
    },
    exclusionIndex() {
      return this.conditions.findIndex(
        (condition) =>
          condition.filters[0]?.comparison === "not_eq" ||
          condition.filters[0]?.comparison === "not_contains"
      );
    },
  },
  watch: {
    inclusionCondition: {
      deep: true,
      handler(newVal) {
        this.refactorUrlSegmentation();
      },
    },
    exclusionCondition: {
      deep: true,
      handler(newVal) {
        this.refactorUrlSegmentation();
      },
    },
  },
  async created() {
    await this.$store.dispatch("product/getProducts", {
      siteId: this.siteId,
      isProductListView: false,
    });

    let inclusionCondition = { filters: [], type: "or" };
    let exclusionCondition = { filters: [], type: "and" };

    if (this.urlSegmentation && this.urlSegmentation.length > 0) {
      this.displayUrlRules = true;
      this.urlSegmentation.forEach((condition) => {
        if (condition.filters) {
          condition.filters.forEach((filterGroup) => {
            if (filterGroup.filters && filterGroup.filters.length > 1) {
              const filters = filterGroup.filters.map((filter) => {
                const { isEditing, ...rest } = filter;
                return rest;
              });

              filters.forEach((filter) => {
                if (
                  filter.comparison === "eq" ||
                  filter.comparison === "contains"
                ) {
                  inclusionCondition.filters.push({
                    ...filter,
                    isEditing: false,
                    isInclusion: true,
                    type: "or",
                  });
                }

                if (
                  filter.comparison === "not_eq" ||
                  filter.comparison === "not_contains"
                ) {
                  exclusionCondition.filters.push({
                    ...filter,
                    isEditing: false,
                    isExclusion: true,
                    type: "and",
                  });
                }
              });
            } else {
              const filter = filterGroup;
              if (
                filter.comparison === "eq" ||
                filter.comparison === "contains"
              ) {
                inclusionCondition.filters.push({
                  ...filter,
                  isEditing: false,
                  isInclusion: true,
                  type: "or",
                });
              }

              if (
                filter.comparison === "not_eq" ||
                filter.comparison === "not_contains"
              ) {
                exclusionCondition.filters.push({
                  ...filter,
                  isEditing: false,
                  isExclusion: true,
                  type: "and",
                });
              }
            }
          });
        } else {
          const filter = condition;
          if (filter.comparison === "eq" || filter.comparison === "contains") {
            inclusionCondition.filters.push({
              ...filter,
              isEditing: false,
              isInclusion: true,
              type: "or",
            });
          }

          if (
            filter.comparison === "not_eq" ||
            filter.comparison === "not_contains"
          ) {
            exclusionCondition.filters.push({
              ...filter,
              isEditing: false,
              isExclusion: true,
              type: "and",
            });
          }
        }
      });
    }

    if (inclusionCondition.filters.length === 0) {
      this.inclusionCondition = null;
    } else {
      this.inclusionCondition = inclusionCondition;
    }

    if (exclusionCondition.filters.length === 0) {
      this.exclusionCondition = null;
    } else {
      this.exclusionCondition = exclusionCondition;
    }

    if (!this.inclusionCondition && !this.exclusionCondition) {
      return;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    calculateTotalQty(productId) {
      const product = this.getProductById(productId);
      if (product && product.variants) {
        let totalQty = product.variants.reduce(
          (total, variant) => total + variant.qty,
          0
        );
        let hasUnlimited = product.variants.some(
          (variant) => variant.sellWithoutStock
        );

        if (hasUnlimited) {
          return this.$t("create.bulk.pagesModal.stock.unlimited");
        } else if (totalQty === 0) {
          return this.$t("create.bulk.pagesModal.stock.outStock");
        } else {
          return totalQty + " " + this.$t("create.bulk.pagesModal.stock.stock");
        }
      }
      return this.$t("create.bulk.pagesModal.stock.outStock");
    },
    firstVariantPrice(product) {
      if (product && product.variants && product.variants.length > 0) {
        const firstVariant = product.variants[0];
        const price = firstVariant ? firstVariant.price : 0;

        if (this.getCurrency === "$") {
          return `$${price}`;
        } else if (this.getCurrency === "€") {
          return `${price}€`;
        } else {
          return `${price}${this.getCurrency}`;
        }
      } else {
        return "";
      }
    },
    getProductById(productId) {
      //For client trois kilos sept, we are using the product key
      if (this.isUsingProductKey)
        return this.products.find(
          (product) => product.productKey === productId
        );

      return this.products.find((product) => product.productId === productId);
    },
    removeProduct(productId) {
      this.$emit(
        "update-product-segmentation",
        this.productIds.filter((id) => id !== productId)
      );
    },
    toggleProductsModal() {
      this.showProductsModal = !this.showProductsModal;
    },
    submitAddToCartProducts(payload) {
      this.productsLoading = true;
      const productIds = payload.map((product) =>
        this.isUsingProductKey ? product.productKey : product.productId
      );

      this.$emit("update-product-segmentation", productIds);

      this.productsLoading = false;
      this.showProductsModal = false;

      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.bulk.pagesModal.productAdded"),
        type: "success",
      });
    },
    getSelectedProducts(productIds) {
      const selectedProducts = this.products.filter((product) =>
        productIds.includes(product.productId)
      );

      return selectedProducts;
    },
    addInclusion() {
      if (this.displayInclusionDropdown === true) {
        return;
      }
      this.isUrlInclusion = true;

      if (!this.inclusionCondition) {
        this.inclusionCondition = {
          filters: [
            {
              comparison: "eq",
              isEditing: false,
              string: "",
              dataType: "URL",
              isInclusion: true,
            },
          ],
          type: "or",
        };
      }
      this.firstFilter = {
        isInclusion: true,
      };

      this.filterForDropdown = this.inclusionCondition.filters[0];

      this.displayInclusionDropdown = true;
    },
    addExclusion() {
      if (this.displayExclusionDropdown === true) {
        return;
      }

      this.isUrlExclusion = true;

      if (!this.exclusionCondition) {
        this.exclusionCondition = {
          filters: [
            {
              comparison: "not_eq",
              isEditing: false,
              string: "",
              dataType: "URL",
              isExclusion: true,
            },
          ],
          type: "and",
        };
      }

      this.firstFilter = {
        isExclusion: true,
      };

      this.filterForDropdown = this.exclusionCondition.filters[0];
      this.displayExclusionDropdown = true;
    },
    updateInclusionCondition(index, newCondition) {
      this.inclusionCondition = newCondition;
    },
    updateExclusionCondition(index, newCondition) {
      this.exclusionCondition = newCondition;
    },
    removeInclusionCondition() {
      this.inclusionCondition = null;
    },
    removeExclusionCondition() {
      this.exclusionCondition = null;
    },
    confirmRule() {
      this.displayInclusionDropdown = false;
      this.displayExclusionDropdown = false;
      this.isUrlInclusion = false;
      this.isUrlExclusion = false;
    },
    shouldShowLogicToggle() {
      return (
        this.inclusionCondition !== null && this.exclusionCondition !== null
      );
    },
    showAddUrlRules() {
      this.displayUrlRules = true;
      this.$nextTick(() => {
        const element = document.querySelector(".scroll-here");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      });
    },
    refactorUrlSegmentation() {
      const combinedCondition = {
        url: [],
      };

      if (
        this.inclusionCondition &&
        this.inclusionCondition.filters.length > 0
      ) {
        const inclusionFilters = this.inclusionCondition.filters.map(
          (filter) => {
            const {
              isEditing,
              isInclusion,
              isExclusion,
              dataType,
              type,
              ...rest
            } = filter;
            return rest;
          }
        );

        if (inclusionFilters.length === 1) {
          combinedCondition.url.push(inclusionFilters[0]);
        } else {
          combinedCondition.url.push({
            filters: inclusionFilters,
            type: "or",
          });
        }
      }

      if (
        this.exclusionCondition &&
        this.exclusionCondition.filters.length > 0
      ) {
        const exclusionFilters = this.exclusionCondition.filters.map(
          (filter) => {
            const {
              isEditing,
              isInclusion,
              isExclusion,
              dataType,
              type,
              ...rest
            } = filter;
            return rest;
          }
        );

        if (exclusionFilters.length === 1) {
          combinedCondition.url.push(exclusionFilters[0]);
        } else {
          combinedCondition.url.push({
            filters: exclusionFilters,
            type: "and",
          });
        }
      }

      if (combinedCondition.url.length > 1) {
        this.$emit("update-url-segmentation", {
          url: [
            {
              filters: combinedCondition.url,
              type: "and",
            },
          ],
        });
      } else {
        this.$emit("update-url-segmentation", combinedCondition);
      }
    },
    handleScroll() {
      const buttons = document.querySelector(".buttons");
      const buttonsRect = buttons.getBoundingClientRect();
      const offset = 50;

      if (buttonsRect.bottom > window.innerHeight - offset) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-product-button {
  @include base-font;
  @include font-smaller;
  padding: 8px 24px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: $dark-purple;
  color: white;
  font-weight: 500;
  border-radius: 3px 0 0 3px;
}
.buttons {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 50px;
  z-index: 20;
}

.delete-icon {
  position: absolute;
  right: 16px;
  cursor: pointer;
}
.add-url-button {
  background-color: rgba(234, 234, 234, 1);
  color: $dark-grey;
  border-radius: 0 3px 3px 0;
  .link-icon {
    path {
      fill: $dark-grey;
    }
  }
}
.logic-toggle {
  @include base-font;
  @include font-smaller;
  margin-bottom: 10px;
  height: 32px;
  border-radius: 2px;
}

.page-exclusion {
  @include base-font;
  @include font-smaller;
  font-weight: 600;
  color: $dark-purple;
  background: transparent;
}

.url-rules {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.inclusion .exclusion {
  position: relative;
}
.inclusion-dropdown {
  left: 0;
  bottom: 138px;
}

.exclusion-dropdown {
  left: 0;
  bottom: 113px;
}
.sticky-buttons {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 10px;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
}
.bottom-page {
  height: 30px;
}

.buttons-with-margin-top {
  margin-top: 24px;
}
</style>
