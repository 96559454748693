<template>
  <div v-click-outside="handleClickOutside" class="condition-line">
    <div
      v-for="(filter, filterIndex) in localCondition.filters"
      :key="filterIndex"
      class="condition"
    >
      <div
        v-if="
          (filter.dataType && filter.dataType !== 'products') ||
          dataType === 'URL'
        "
        class="condition-text"
        :class="{ 'condition-text-without-value': !filter.string }"
        @click="openDropdown(filterIndex)"
      >
        <LinkIcon
          v-if="filter.dataType === 'url' || dataType === 'URL'"
          class="link-icon"
        />
        <BrandIcon v-if="filter.dataType === 'brand'" />
        <CategoryIcon v-if="filter.dataType === 'category'" />
        <p v-if="dataType" class="condition-part">{{ dataType }}</p>
        <p v-else class="condition-part">
          {{ translatedDataType(filter.dataType) }}
        </p>
        <p
          v-if="filter.comparison === 'eq' && filter.string"
          class="condition-part"
        >
          {{ $t("advancedSegmentation.eq") }}
        </p>
        <p
          v-if="filter.comparison === 'not_eq' && filter.string"
          class="condition-part"
        >
          {{ $t("advancedSegmentation.not_eq") }}
        </p>
        <p
          v-if="filter.comparison === 'contains' && filter.string"
          class="condition-part"
        >
          {{ $t("advancedSegmentation.contains") }}
        </p>
        <p
          v-if="filter.comparison === 'not_contains' && filter.string"
          class="condition-part"
        >
          {{ $t("advancedSegmentation.not_contains") }}
        </p>
        <p v-if="!filter.string && filter.dataType !== 'products'">
          {{ $t("advancedSegmentation.missing") }}
        </p>
        <p class="condition-part bold-part">
          {{ truncateValue(filter.string) }}
        </p>
        <button class="remove-button" @click="remove(filterIndex)">
          <SquaredX />
        </button>
      </div>
      <div
        v-if="filter.productId"
        class="condition-text"
        @click="
          openProductModal(filter.productId, filterIndex, filter.comparison)
        "
      >
        <ProductIcon />
        <p class="condition-part">{{ translatedDataType(filter.dataType) }}</p>
        <p v-if="filter.comparison === 'eq'" class="condition-part">
          {{ $t("advancedSegmentation.included") }}
        </p>
        <p v-if="filter.comparison === 'not_eq'" class="condition-part">
          {{ $t("advancedSegmentation.excluded") }}
        </p>
        <p class="condition-part">{{ filter.productId.length }}</p>
        <button class="remove-button" @click="remove(filterIndex)">
          <SquaredX />
        </button>
      </div>
      <button
        v-if="
          (filter.string || filter.productId) &&
          filterIndex === localCondition.filters.length - 1
        "
        class="condition-text plus"
        @click="addFilter(filterIndex)"
      >
        +
      </button>

      <SegmentationFilterDropdown
        v-if="filter.isEditing"
        :filter="filter"
        :is-advanced-segmentation="!isUrlSegmentation"
        :first-filter="localCondition.filters[0]"
        :is-url-inclusion="isUrlInclusion"
        :is-url-exclusion="isUrlExclusion"
        @close="closeDropdown(filterIndex)"
      />
      <button
        v-if="
          localCondition.filters.length > 1 &&
          filterIndex < localCondition.filters.length - 1
        "
        class="logic-toggle"
        @click="toggleLogic"
      >
        {{ condition.type }}
      </button>
      <SegmentationDataTypeModal
        v-if="activeFilterIndex === filterIndex"
        @select="onDataTypeSelected"
        @close="closeDataTypeModal"
      />
    </div>
    <AddToCartModal
      v-if="showProductsModal"
      :is-full-screen="true"
      :products-loading="productsLoading"
      :is-advanced-segmentation="true"
      :selected-products-edit="selectedProducts"
      :products-comparison-value="editingProductsComparison"
      @close-add-to-cart="closeAddToCartModal"
      @products-to-advanced-segmentation="submitAddToCartProducts"
    />
  </div>
</template>

<script>
import SegmentationFilterDropdown from "@/components/shared/SegmentationFilterDropdown.vue";
import SegmentationDataTypeModal from "@/components/shared/SegmentationDataTypeModal.vue";
import AddToCartModal from "@/components/create-campaign-steps/step3/AddToCartModal.vue";
import LinkIcon from "@/assets/svg/small-url-icon.svg?inline";
import ProductIcon from "@/assets/svg/small-product-icon.svg?inline";
import CategoryIcon from "@/assets/svg/category-icon.svg?inline";
import BrandIcon from "@/assets/svg/brand-icon.svg?inline";
import SquaredX from "@/assets/svg/squared-x-icon.svg?inline";
import { mapState } from "vuex";

export default {
  components: {
    SegmentationFilterDropdown,
    SegmentationDataTypeModal,
    LinkIcon,
    SquaredX,
    ProductIcon,
    CategoryIcon,
    BrandIcon,
    AddToCartModal,
  },
  props: {
    condition: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    firstDataType: {
      type: String,
      default: null,
    },
    isUrlSegmentation: {
      type: Boolean,
      default: false,
    },
    isUrlInclusion: {
      type: Boolean,
      default: false,
    },
    isUrlExclusion: {
      type: Boolean,
      default: false,
    },
    // editingProductsComparison: {
    //   type: String,
    //   default: "eq",
    // },
    dataType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      displayDataTypeModal: false,
      productsLoading: false,
      showProductsModal: false,
      selectedProducts: null,
      isEditingProductsFilter: false,
      editingProductsFilterIndex: null,
      localCondition: null,
      preventDropdown: false,
      activeFilterIndex: null,
      editingProductsComparison: "eq",
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.product.products,
    }),
  },
  // watch: {
  //   localCondition: {
  //     deep: true,
  //     handler(newCondition) {
  //       this.$emit("updateCondition", this.index, newCondition);
  //     },
  //   },
  // },
  created() {
    this.localCondition = { ...this.condition };
  },
  methods: {
    toggleLogic() {
      if (this.isUrlSegmentation) {
        return;
      }
      this.localCondition.type =
        this.localCondition.type === "and" ? "or" : "and";
      this.$emit("updateCondition", this.index, { ...this.localCondition });
    },
    remove(filterIndex) {
      this.preventDropdown = true;
      const updatedFilters = this.localCondition.filters
        .slice()
        .filter((_, index) => index !== filterIndex);

      this.$set(this.localCondition, "filters", updatedFilters);

      this.$emit("updateCondition", this.index, { ...this.localCondition });

      if (this.localCondition.filters.length === 0) {
        this.$emit("removeCondition", this.index);
      }
      setTimeout(() => {
        this.preventDropdown = false;
      }, 200);
    },
    handleClickOutside() {
      this.localCondition.filters.forEach((filter) => {
        filter.isEditing = false;
      });
    },
    addFilter(filterIndex) {
      if (!this.isUrlSegmentation) {
        this.openDataTypeModal(filterIndex);
        return;
      }
      if (this.isUrlExclusion) {
        this.localCondition.filters.push({
          comparison: "not_eq",
          string: "",
          isEditing: true,
          dataType: "URL",
        });
      } else {
        this.localCondition.filters.push({
          comparison: "eq",
          string: "",
          isEditing: true,
          dataType: "URL",
        });
      }

      this.localCondition.filters.forEach((filter, index) => {
        if (index !== this.localCondition.filters.length - 1)
          filter.isEditing = false;
      });
    },
    openDropdown(filterIndex) {
      if (this.preventDropdown) {
        return;
      }

      this.localCondition.filters.forEach((filter, index) => {
        filter.isEditing = index === filterIndex;
      });
    },
    closeDropdown(filterIndex) {
      this.localCondition.filters[filterIndex].isEditing = false;
      this.$emit("updateCondition", this.index, this.localCondition);
    },
    truncateValue(string) {
      return string && string.length > 21
        ? string.substring(0, 21) + "..."
        : string;
    },
    onDataTypeSelected(dataType) {
      if (dataType === "products") {
        this.showProductsModal = true;
        return;
      }

      this.localCondition.filters.push({
        comparison: "eq",
        string: "",
        isEditing: true,
        dataType: dataType,
      });

      this.displayDataTypeModal = false;
    },
    openDataTypeModal(filterIndex) {
      this.activeFilterIndex = filterIndex;
    },
    closeDataTypeModal() {
      this.activeFilterIndex = null;
    },
    closeAddToCartModal() {
      this.showProductsModal = false;
      this.selectedProducts = null;
    },
    submitAddToCartProducts(payload, rule) {
      const productIds = payload.map((product) => product.productId);
      if (this.isEditingProductsFilter) {
        this.localCondition.filters[this.editingProductsFilterIndex] = {
          ...this.localCondition.filters[this.editingProductsFilterIndex],
          comparison: rule,
          productId: productIds,
          isEditing: false,
        };
      } else {
        this.localCondition.filters.push({
          comparison: rule,
          productId: productIds,
          isEditing: false,
          dataType: "products",
        });
      }
      this.$emit("updateCondition", this.index, this.localCondition);

      this.showProductsModal = false;
      this.isEditingProductsFilter = false;
      this.editingProductsFilterIndex = null;
    },

    openProductModal(productIds, index, comparison) {
      if (this.preventDropdown) {
        return;
      }
      this.isEditingProductsFilter = true;
      this.editingProductsFilterIndex = index;
      this.editingProductsComparison = comparison;
      this.getSelectedProducts(productIds);
      this.showProductsModal = true;
    },
    getSelectedProducts(productIds) {
      this.selectedProducts = this.products.filter((product) =>
        productIds.includes(product.productId)
      );
      return this.selectedProducts;
    },
    translatedDataType(dataType) {
      return this.$t(`advancedSegmentation.${dataType}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.condition-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}
.link-icon {
  path {
    fill: $dark-grey;
  }
}

.condition {
  display: flex;
  align-items: center;
  gap: 10px;
}
.remove-button {
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(234, 234, 234, 1);
  cursor: pointer;
  display: none;
  align-items: center;
  padding: 2px 8px;
  z-index: 100;
  &::before {
    content: "";
    position: absolute;
    left: -8px;
    top: 0;
    bottom: 0;
    width: 8px;
    background-color: rgba(234, 234, 234, 1);
    filter: blur(6px);
    z-index: -1;
  }
}

.condition-text {
  @include base-font;
  @include font-smaller;
  height: 32px;
  box-sizing: border-box;
  font-weight: 500;
  align-items: center;
  padding: 8px 12px;
  background-color: rgba(234, 234, 234, 1);
  border-radius: 2px;
  position: relative;
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  gap: 4px;
  cursor: pointer;
  &:hover {
    background-color: rgba(224, 224, 224, 1);
    .remove-button {
      display: flex;
      background-color: rgba(224, 224, 224, 1);
    }
  }
}

.plus {
  font-size: 14px;
}

.condition-part {
  display: inline-block;
}
.bold-part {
  font-weight: 600;
}
.logic-toggle {
  @include base-font;
  @include font-smaller;
  margin-right: 10px;
  height: 32px;
  border-radius: 2px;
}
.condition-text-without-value {
  background-color: rgba(255, 204, 204, 1);
  &:hover {
    background-color: rgba(255, 173, 173, 1);
    .remove-button {
      display: flex;
      background-color: rgba(255, 173, 173, 1);
      &::before {
        background-color: rgba(255, 173, 173, 1);
      }
    }
  }
}
</style>
