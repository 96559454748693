<template>
  <sliding-modal
    class="url-sliding-modal-wrapper"
    :title="urlsTitle"
    :feed-name="currentFeedEdit.feedName"
    :is-segmentation-title="true"
    @close="close"
  >
    <div class="buttons-container">
      <div class="buttons">
        <button class="cancel-button flex-button" @click="close">
          {{ $t("create.bulk.modalButtons.leave")
          }}<SmallSquaredX class="button-icon" />
        </button>
        <button class="save-button flex-button" @click="savePagesSegmentation">
          {{ $t("create.bulk.modalButtons.save")
          }}<SquaredCheckIcon class="button-icon" />
        </button>
      </div>
    </div>
    <div class="url-sliding-container">
      <div class="content">
        <PagesSegmentation
          :segmentation="currentFeedEdit.segmentation"
          @updateSegmentation="updateCurrentFeedSegmentation"
          @update-advanced-segmentation="updateAdvancedSegmentation"
          @option-selected="changeSegmentationType"
        />
      </div>
    </div>
    <CloseSegmentationModal
      v-if="showCloseSegmentationModal"
      @close="showCloseSegmentationModal = false"
      @confirm="confirmClosingSegmentation"
    />
  </sliding-modal>
</template>
<script>
import SlidingModal from "@/components/shared/SlidingModal.vue";
import deepClone from "@/utils/deepClone.js";
import PagesSegmentation from "@/components/create-campaign-steps/PagesSegmentation.vue";
import CloseSegmentationModal from "@/components/shared/CloseSegmentationModal.vue";
import SquaredCheckIcon from "@/assets/svg/squared-check-icon.svg?inline";
import SmallSquaredX from "@/assets/svg/small-squared-x-icon.svg?inline";

import { mapGetters } from "vuex";

export default {
  components: {
    SlidingModal,
    PagesSegmentation,
    CloseSegmentationModal,
    SquaredCheckIcon,
    SmallSquaredX,
  },
  props: {
    currentFeedEditIndex: { type: Number, required: true },
  },
  data() {
    return {
      urlsTitle: this.$t("create.bulk.pagesModal.title"),
      areAllInputsFilled: true,
      equalTriggersHaveValidUrls: true,
      showCloseSegmentationModal: false,
      segmentationSelected: null,
    };
  },
  computed: {
    ...mapGetters({
      siteId: "account/getSiteId",
      currentFeedEdit: "campaign/currentFeedEdit",
      feedList: "campaign/feedList",
      getCurrency: "site/getCurrency",
    }),
  },
  methods: {
    confirmClosingSegmentation() {
      this.$emit("close-urls-modal");
    },
    close() {
      if (
        this.currentFeedEdit.segmentation &&
        Object.keys(this.currentFeedEdit.segmentation).length > 0
      ) {
        this.showCloseSegmentationModal = true;
      } else {
        this.$emit("close-urls-modal");
      }
    },
    updateCurrentFeedSegmentation(payload) {
      if (payload.hasOwnProperty("areAllInputsFilled"))
        this.areAllInputsFilled = payload.areAllInputsFilled;
      if (payload.hasOwnProperty("equalTriggersHaveValidUrls"))
        this.equalTriggersHaveValidUrls = payload.equalTriggersHaveValidUrls;

      delete payload.equalTriggersHaveValidUrls;
      delete payload.areAllInputsFilled;
      this.$store.commit(
        "campaign/setCurrentFeedEdit",
        deepClone({
          ...this.currentFeedEdit,
          segmentation: payload,
        })
      );
    },
    savePagesSegmentation() {
      if (!this.areAllInputsFilled) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.segmentation"),
          type: "error",
        });
      }

      if (!this.equalTriggersHaveValidUrls) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.wrongUrl"),
          type: "error",
        });
      }

      let updatedSegmentation = deepClone(this.currentFeedEdit.segmentation);

      if (this.segmentationSelected === "advanced") {
        updatedSegmentation = { advanced: updatedSegmentation.advanced };
      } else {
        delete updatedSegmentation.advanced;
      }

      this.$store.commit(
        "campaign/setCurrentFeedEdit",
        deepClone({
          ...this.currentFeedEdit,
          segmentation: updatedSegmentation,
        })
      );

      this.$store.commit("campaign/updateFeedList", {
        currentFeedEdit: deepClone(this.currentFeedEdit),
        index: this.currentFeedEditIndex,
      });

      this.$store.dispatch("campaign/updateFeed", {
        campaignId: this.$route.params.campaignId,
        feedIndex: this.currentFeedEditIndex,
      });

      this.confirmClosingSegmentation();
    },
    updateAdvancedSegmentation(payload) {
      this.$store.commit(
        "campaign/setCurrentFeedEdit",
        deepClone({
          ...this.currentFeedEdit,
          segmentation: payload.segmentation,
        })
      );
    },
    changeSegmentationType(option) {
      this.segmentationSelected = option;
    },
  },
};
</script>
<style lang="scss" scoped>
.url-sliding-wrapper {
  position: relative;
}

.url-sliding-container {
  display: flex;
  flex-direction: column;
  min-height: 90%;
  margin: 0 40px;
  box-sizing: border-box;
}
.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  text-align: center;
  width: 70%;
  margin: 0 auto 16px;
}

.content {
  flex: 1;
  overflow-y: auto;
  margin-top: 24px;
}

.content::-webkit-scrollbar {
  width: 0;
}

.content::-webkit-scrollbar-track {
  background-color: transparent;
}

.content::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.buttons-container {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 40px;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.flex-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tooltip-icon {
  width: 15px;
  &:hover {
    cursor: pointer;
  }
}
</style>
