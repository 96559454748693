import api from "../api";
import Vue from "vue";

import enums from "../enums";

const { StateFlag, CtaAction } = enums;

const getVideoWithIdsOnly = (video) => {
  const newObj = {
    videoId: video._id,
  };

  video.textOptions?.text && (newObj.textOptions = video.textOptions);
  video.thumbnailGrouping &&
    (newObj.thumbnailGrouping = video.thumbnailGrouping);
  video.isMainGrouping && (newObj.isMainGrouping = video.isMainGrouping);

  if (video.cta && video.cta.ctaType > -1) {
    let cta = structuredClone(video.cta);
    if (cta.products && cta.products.length > 0) {
      cta.productIds = cta.products.map((product) => product._id);
      delete cta.products;
    }

    newObj.cta = cta;
  }

  return newObj;
};

const state = {
  // have one campaign
  campaigns: [],
  campaignCreation: null,
  campaignSelected: null,
  singleCampaign: null,
  currentFeedEdit: null,
  feedList: [],
};
// TODO refactoring can be made here
const getters = {
  campaignSelected: (state) => (id) => {
    return id ? state.campaigns.find((item) => item._id === id) : null;
  },
  campaignCreation: (state) => () => {
    return state.campaignCreation;
  },
  getSingle: (state) => () => {
    return state.singleCampaign;
  },
  getSingleCampObj: (state) => {
    return state.singleCampaign;
  },
  hasCampaigns: (state) => {
    return Boolean(state.campaigns.length);
  },
  videoObject: (state) => {
    return state.campaignCreation && state.campaignCreation.videos;
  },
  activeCampaigns: (state) => {
    return state.campaigns.filter((campaign) => campaign.stateFlag === 1);
  },
  numberOfCampaigns: (state) => {
    return state.campaigns.length;
  },
  numberOfVideosInCampaigns: (state) => {
    const getAmountOfVideos = (campaign) => {
      return campaign.videos
        ? campaign.videos.length
        : campaign.bulk.reduce((total, feed) => total + feed.videos.length, 0);
    };

    return state.campaigns.reduce(
      (total, campaign) => total + getAmountOfVideos(campaign),
      0
    );
  },
  currentFeedEdit: (state) => {
    return state.currentFeedEdit;
  },
  feedList: (state) => {
    return state.feedList;
  },
  defaultFormatObject: (state) => {
    return {
      autoLoop: false,
      autoScroll: false,
      autoplay: false,
      hidePlayPause: true,
      hideControlBar: false,
      title: "",
      hideFeedArrow: false,
      iconSettings: {
        iconType: "minimal",
        animation: "none",
        secondaryColor: "#c4c4c4",
        color: "white",
        opacity: 1,
      },
      cta: {
        textColor: "#FFFFFF",
        buttonColor: "#000000",
        ctaAction: CtaAction.buyNow,
        buttonShape: "semi",
      },
    };
  },
  defaultEmbedFormatObject: (state) => {
    return {
      formatType: 2,
      responsive: true,
      embedSize: {
        height: 0,
        width: 0,
      },
      thumbnailBorders: {
        isActive: false,
        size: "2",
        color: "#000000",
      },
      thumbnailShadow: false,
      animatedThumbnail: "all",
      textOptions: {},
    };
  },
  defaultStoryFormatObject: (state) => {
    return {
      formatType: 3,
      isFullScreen: true,
      isStory: true,
      hideStoryPlay: false,
      borderColor: "#FFA500",
      deviceType: 1,
      animatedThumbnail: "all",
      textOptions: {},
    };
  },
  defaultCarouselFormatObject: (state) => {
    return {
      formatType: 3,
      deviceType: 1,
      arrowSettings: {
        iconType: "corner1",
        secondaryColor: "#A3A3A3",
        color: "black",
        opacity: 1,
      },
      quickShop: {
        isActive: false,
        productTextColor: "#FFFFFF",
        productBackgroundColor: "#000000",
        buttonTextColor: "#FFFFFF",
        buttonBackgroundColor: "#000000",
        ctaAction: CtaAction.addtoCart,
      },
      thumbnailBorders: {
        isActive: false,
        size: "2",
        color: "#000000",
      },
      thumbnailShadow: false,
      animatedThumbnail: "all",
      textOptions: {},
    };
  },
  defaultQuickShopFormatObject: (state) => {
    return {
      formatType: 3,
      deviceType: 1,
      arrowSettings: {
        iconType: "corner1",
        secondaryColor: "#A3A3A3",
        color: "black",
        opacity: 1,
      },
      quickShop: {
        isActive: true,
        productTextColor: "#FFFFFF",
        productBackgroundColor: "#000000",
        buttonTextColor: "#FFFFFF",
        buttonBackgroundColor: "#000000",
        ctaAction: CtaAction.addtoCart,
      },
    };
  },
  defaultBubbleProperties: (state) => {
    return {
      formatType: 1,
      isStickyBar: false,
      openByDefault: false,
      position: 0,
      bubbleSize: 90,
      bubbleShape: "round",
      moveX: 0,
      moveY: 0,
      bubbleBorder: false,
      bubbleBorderColor: "#000000",
      isFullScreen: false,
      hideBubble: true,
    };
  },
};

const mutations = {
  getCampaigns(state, campaigns) {
    state.campaigns = campaigns;
  },
  CREATE_CAMPAIGN(state, campaignCreated) {
    state.campaigns.push(campaignCreated);
  },
  updateCampaign(state, { campaign, index }) {
    if (typeof index !== "undefined") {
      // this is like state.campaigns[index] = campaign
      Vue.set(state.campaigns, index, campaign);
    } else {
      const indexOfCampaign = state.campaigns.findIndex(
        (item) => item._id === campaign._id
      );

      Vue.set(state.campaigns, indexOfCampaign, campaign);
    }
  },
  deleteCampaign(state, campaignId) {
    state.campaigns.splice(
      state.campaigns.findIndex((item) => item._id === campaignId),
      1
    );
  },
  campaignCreationInit(state, { dataToUpdate }) {
    state.campaignCreation = { ...dataToUpdate };
  },

  UPDATE_CAMPAIGN_CREATION(state, { dataToUpdate }) {
    state.campaignCreation = { ...state.campaignCreation, ...dataToUpdate };
  },
  stopCampaignCreation(state) {
    state.campaignCreation = null;
  },
  campaignCreationSetId(state, id) {
    state.campaignCreation._id = id;
  },
  campaignCreationSetSiteId(state, id) {
    state.campaignCreation.siteId = id;
  },
  campaignCreationSetVideos(state, videos) {
    state.campaignCreation.videos = videos;
  },
  campaignCreationResetVideo(state) {
    state.campaignCreation.videos = {};
  },
  campaignCreationSetFormat(state, { formats }) {
    state.campaignCreation.formats = formats;
  },
  campaignCreationSetSegmentation(state, { segmentation }) {
    state.campaignCreation.segmentation = segmentation;
  },
  campaignCreationSetMissingData(state) {
    state.campaignCreation.order =
      (state.campaigns && state.campaigns.length) || 1;
    // state.campaignCreation.stateFlag = StateFlag.paused;
  },
  resetCampaignSelected(state) {
    state.campaignSelected = null;
  },
  setCampaignSelected(state, campaignId) {
    state.campaignSelected = campaignId;
  },
  singleCampaign(state, campaign) {
    // if campaign is null, we reset the singleCampaign
    if (!campaign) {
      state.singleCampaign = {};
    } else {
      state.singleCampaign = { ...state.singleCampaign, ...campaign };
    }
  },
  updateSingleCampaignData(state, dataToUpdate) {
    state.singleCampaign = { ...state.singleCampaign, ...dataToUpdate };
  },
  UPDATE_SINGLE_CAMPAIGN_VIDEO(state, campaign) {
    state.singleCampaign = campaign;
  },
  setFeedList(state, feedList) {
    state.feedList = feedList;
  },
  setCurrentFeedEdit(state, feed) {
    state.currentFeedEdit = feed;
  },
  createFeed(state, newFeed) {
    state.feedList.push(newFeed);
  },
  updateFeedList(state, { currentFeedEdit, index }) {
    const newFeedList = [...state.feedList];
    newFeedList[index] = currentFeedEdit;
    state.feedList = newFeedList;
    // state.feedList[index] = currentFeedEdit;
  },
  deleteFeed(state, feedIndex) {
    state.feedList.splice(feedIndex, 1);
  },
  resetCampaignState(state) {
    state.campaignCreation = null;
    state.campaignSelected = null;
    state.singleCampaign = null;
  },
  updateSingleCampaignVideos(state, videos) {
    if (state.singleCampaign) {
      Vue.set(state.singleCampaign, "videos", videos);
    } else {
      state.singleCampaign = { videos };
    }
  },
};

const actions = {
  async getCampaigns({ commit }, { siteId }) {
    const campaignsList = await api.getCampaigns({ siteId });
    commit("getCampaigns", campaignsList);
    return campaignsList;
  },
  async getSingle({ commit }, { siteId, campaignId }) {
    try {
      const campaign = await api.getSingleCampaign(campaignId);

      if (campaign.siteId != siteId)
        throw new Error("Requested campaign was not created by current user");
      await commit("singleCampaign", campaign);
      return { status: "success", res: "campaign.getSingle", campaign };
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },
  async createCampaign({ commit }) {
    commit("campaignCreationSetMissingData");
    try {
      let campaign = Object.assign({}, this.state.campaign.campaignCreation);
      if (!campaign.bulk) {
        campaign.videos = campaign.videos.map((video) =>
          getVideoWithIdsOnly(video)
        );
      }
      let campaignCreated = await api.createCampaign(campaign);
      // We need to set a campaign document id so that we can use it in step 5
      await commit("campaignCreationSetId", campaignCreated._id);
      commit("CREATE_CAMPAIGN", campaignCreated);

      return {
        status: "success",
        res: "campaign.added",
        id: campaignCreated._id,
      };
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },
  // update getSingle
  updateSingleCampaign({ commit }, dataToUpdate) {
    commit("singleCampaign", dataToUpdate);
  },
  async updateCampaign(
    { commit },
    { campaignId, dataToUpdate, isLaunchCampaign = false, index }
  ) {
    try {
      if (dataToUpdate.bulk) {
        dataToUpdate.bulk = dataToUpdate.bulk.map((feed) => {
          return {
            ...feed,
            videos: feed.videos.map((video) => getVideoWithIdsOnly(video)),
          };
        });
      } else if (dataToUpdate.videos) {
        dataToUpdate.videos = dataToUpdate.videos.map((video) =>
          getVideoWithIdsOnly(video)
        );
      }

      const campaignUpdated = await api.updateCampaign(
        campaignId,
        dataToUpdate
      );
      !isLaunchCampaign
        ? await commit("updateCampaign", { campaign: campaignUpdated, index })
        : "";
      return {
        status: "success",
        res: "campaign.updated",
        id: campaignUpdated._id,
      };
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },
  async deleteCampaign({ commit }, campaignId) {
    try {
      const campaigns = await api.deleteCampaign(campaignId);

      //We commit for getCampaigns because the delete function returns
      //the list of campaigns without the one deleted and in good order
      await commit("getCampaigns", campaigns);

      return { status: "success", res: "campaign.deleted", id: campaignId };
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },
  async cloneCampaign({ commit }, campaign) {
    try {
      const campaignToClone = Object.assign({}, campaign);
      delete campaignToClone._id;
      delete campaignToClone.modificationDate;
      delete campaignToClone.creationDate;
      delete campaignToClone.views;
      delete campaignToClone.displays;
      delete campaignToClone.urlsWithDisplay;
      campaignToClone.order = this.state.campaign.campaigns.length;
      campaignToClone.name += " (Copy)";
      campaignToClone.stateFlag = StateFlag.paused;

      const campaignCreated = await api.createCampaign(campaignToClone);
      campaignCreated.views = 0;
      campaignCreated.displays = 0;
      campaignCreated.urlsWithDisplay = [];
      commit("CREATE_CAMPAIGN", campaignCreated);
      return {
        status: "success",
        res: "campaign.cloned",
        id: campaignCreated._id,
      };
    } catch (err) {
      throw new Error(err);
    }
  },
  async scheduler({ commit }, campaignData) {
    try {
      await api.campaignScheduler(campaignData);
      return {
        status: "success",
        res: "campaign.scheduled",
        id: campaignData.campaignId,
      };
    } catch (err) {
      throw new Error(err);
    }
  },
  async resetCampaignSelected({ commit }) {
    await commit("resetCampaignSelected");
  },
  resetVideo({ commit }) {
    commit("campaignCreationResetVideo");
  },
  async setCampaignSelected({ commit }, campaignId) {
    await commit("setCampaignSelected", campaignId);
  },
  async createBulkCampaign({ commit, state }, { siteId, formats, tags }) {
    const bulkCampaign = {
      name: "Bulk Embed",
      formats,
      bulk: [],
      siteId,
      tags,
      order: (state.campaigns && state.campaigns.length) || 1,
      stateFlag: StateFlag.paused,
    };

    const createdBulkCampaign = await api.createCampaign(bulkCampaign);

    commit("CREATE_CAMPAIGN", createdBulkCampaign);
    commit("singleCampaign", createdBulkCampaign);

    return createdBulkCampaign;
  },
  async updateFeed({ commit, state }, { campaignId, feedIndex, feedData }) {
    commit("updateSingleCampaignData", {
      modificationDate: new Date().toISOString(),
    });

    if (!feedData) {
      feedData = {
        ...state.currentFeedEdit,
        videos: state.currentFeedEdit.videos.map((video) =>
          getVideoWithIdsOnly(video)
        ),
      };
    }

    if (feedData.videos[0]._id) {
      feedData.videos = feedData.videos.map((video) =>
        getVideoWithIdsOnly(video)
      );
    }

    await api.updateFeed(campaignId, {
      feedIndex,
      feedData,
    });
  },
  async createFeed({ commit }, { campaignId, newFeed }) {
    commit("createFeed", newFeed);

    const feedToCreate = { ...newFeed };

    if (feedToCreate.videos.length) {
      feedToCreate.videos = feedToCreate.videos.map((video) =>
        getVideoWithIdsOnly(video)
      );
    }

    commit("updateSingleCampaignData", {
      modificationDate: new Date().toISOString(),
      bulk: state.feedList,
    });

    await api.createFeed(campaignId, feedToCreate);

    return newFeed;
  },
  async deleteFeed({ commit }, { campaignId, feedIndex }) {
    commit("deleteFeed", feedIndex);

    commit("updateSingleCampaignData", {
      modificationDate: new Date().toISOString(),
    });

    await api.deleteFeed(campaignId, feedIndex);
  },
  async addVideosToWidget({ commit }, { campaignId, videosIds }) {
    await api.addVideosToWidget(campaignId, { videosIds });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
