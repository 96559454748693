<template>
  <small-modal :fit-content="true" @close="close">
    <div class="title-banner-wrapper">
      <h1 class="title">
        {{ $t("billing.creditCardInformation.modal.title") }}
      </h1>
      <h2 class="subtitle">
        {{ $t("billing.creditCardInformation.modal.subtitle") }}
      </h2>
      <banner-modal
        class="banner"
        icon-type="info"
        :title="$t('deleteCampaign.information')"
        :text="$t('billing.creditCardInformation.modal.bannerText')"
      >
        <template #icon>
          <InfoIcon />
        </template>
        <CreditCardImages class="credit-card-images" />
      </banner-modal>
    </div>
    <form class="form" @submit.prevent="addCreditCard">
      <ul class="name-and-number">
        <CreditCardForm
          :input-value="creditCardInputValue"
          @input-change="updateCreditCardInputValue"
        />
      </ul>
      <div class="credit-card-modal-buttons">
        <input
          class="button-primary back-button"
          :value="$t('billing.creditCardInformation.modal.cancel')"
          @click="close"
        />
        <label class="button-primary flex-button">
          <input
            class="confirm-button"
            type="submit"
            :value="$t('billing.creditCardInformation.modal.save')"
          />
          <ConfirmIcon />
        </label>
      </div>
    </form>
  </small-modal>
</template>

<script>
import { mapState } from "vuex";
import SmallModal from "../../shared/SmallModal";
import CreditCardForm from "@/components/settings/settings-components/CreditCardForm.vue";
import CreditCardImages from "./CreditCardImages.vue";
import BannerModal from "../../shared/BannerModal.vue";
import InfoIcon from "@/assets/svg/banner-info-icon.svg?inline";
import ConfirmIcon from "@/assets/svg/confirm-icon.svg?inline";

export default {
  components: {
    SmallModal,
    CreditCardImages,
    BannerModal,
    InfoIcon,
    ConfirmIcon,
    CreditCardForm,
  },

  data() {
    return {
      creditCardInputValue: null,
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    close() {
      this.$emit("close");
    },

    async addCreditCard() {
      const dataToAdd = {
        userId: this.user.user._id,
        siteId: this.user.user.siteId,
        holderName: this.creditCardInputValue.cardHolderName,
        cvc: this.creditCardInputValue.cvc,
        number: this.creditCardInputValue.cardNumber.replace(/ /g, ""),
        expMonth: parseInt(this.creditCardInputValue.expDate.substring(0, 2), 10),
        expYear: parseInt(this.creditCardInputValue.expDate.slice(-2), 10) + 2000,
      };
      if (
        this.creditCardInputValue.cvc.length >= 3 &&
        dataToAdd.number.length > 14 &&
        dataToAdd.number.length < 17
      ) {
        try {
          await this.$store.dispatch("plan/addCreditCard", { dataToAdd });
          this.$emit("close");
          this.$notify({
            title: this.$t("shared.toastMessage.creditCardUpdated"),
            type: "success",
          });
        } catch (err) {
          console.log(err);
          this.$notify({
            title: err,
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: this.$t("shared.toastMessage.wrongCardNumber"),
          type: "error",
        });
      }
    },
    updateCreditCardInputValue(value) {
      this.creditCardInputValue = { ...value }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-banner-wrapper {
  @include flex-col-gap(16px);
  align-items: center;
  margin-bottom: 16px;
}

.title {
  @include font-big;
  @include base-font;
  font-weight: 600;
}
.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  white-space: nowrap;
}

.credit-card-images {
  position: absolute;
  right: 0;
  top: 12px;
  transform: scale(0.75);
}
.card-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}
.name-and-number {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 28px;
  li {
    display: inherit;
    justify-content: space-between;
  }
}

.date-and-cvc {
  display: flex;
  justify-content: space-between;
}

.credit-card-label {
  @include font-small;
  font-weight: 500;
  justify-self: flex-start;
}

#name,
#card-number {
  @include base-font;
  @include font-normal;
  @include input-style;
  padding: 3px;
  width: 100%;
  float: right;
}

#exp-date,
#cvc {
  @include base-font;
  @include font-normal;
  width: 100%;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 3px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
.credit-card-modal-buttons {
  display: flex;
  justify-content: space-evenly;
}
.back-button {
  width: 115px;
  text-align: center;
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.18);
  &:hover {
    background-color: rgba(0, 0, 0, 0.18);
  }
}
.confirm-button {
  background: none;
  border: none;
  @include base-font;
  @include font-small;
  font-weight: 500;
}
.flex-button {
  display: flex;
  align-items: center;
}

@include media(">phone", "<=tablet") {
  .card-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include font-normal;
  }

  .name-and-number {
    width: 90%;
    margin-bottom: 8px;
  }

  .credit-card-label,
  #name,
  #card-number,
  #exp-date,
  #cvc {
    @include font-small;
  }

  #name,
  #card-number {
    width: 60%;
  }

  .buttons {
    margin-top: 16px;
  }
}

@include media("<=phone") {
  .card-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include font-normal;
  }

  .name-and-number {
    width: 90%;
    margin-bottom: 8px;
  }

  .credit-card-label,
  #name,
  #card-number,
  #exp-date,
  #cvc {
    @include font-smaller;
  }

  #name,
  #card-number {
    width: 60%;
  }
}
</style>
