<template>
  <small-modal
    :title="$t('create.step3.groupingThumbnailsModal.title')"
    class="grouping-thumbnail-modal"
    :fit-content="true"
    @close="close"
  >
    <div class="grouping-thumbnail-modal-content">
      <h2 class="grouping-thumbnail-modal-subtitle">
        {{ $t("create.step3.groupingThumbnailsModal.subtitle") }}
      </h2>
      <banner-modal
        banner-type="info"
        :title="$t('deleteCampaign.information')"
        :text="$t('create.step3.groupingThumbnailsModal.banner')"
      >
        <a
          class="hyperlink"
          href="https://help.vidjet.io/en/articles/9133553-grouping-videos-into-a-single-thumbnail"
          target="_blank"
          >{{ $t("create.step3.groupingThumbnailsModal.link") }}</a
        >
      </banner-modal>
      <div class="buttons">
        <button class="button-primary" @click="close">
          {{ $t("create.step3.leaveModal.cancel") }}
        </button>
        <button class="button-primary" @click="confirmGroupThumbnail">
          {{ $t("shared.buttons.confirm") }}
        </button>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal";
import BannerModal from "@/components/shared/BannerModal.vue";

export default {
  components: {
    SmallModal,
    BannerModal,
  },
  props: {
    video: { type: Object, default: () => {} },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmGroupThumbnail() {
      this.$emit("group-thumbnail", this.video);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.grouping-thumbnail-modal-container {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.grouping-thumbnail-modal-subtitle {
  text-align: center;
  @include font-normal;
  margin-bottom: 24px;
  font-weight: 400;
}
.hyperlink {
  display: block;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 36px;
}

.button-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.submit-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  background: none;
  border: none;
}
</style>
