<template>
  <div>
    <div>
      <p class="where-to-show">{{ $t("advancedSegmentation.where") }}</p>
      <div v-for="(condition, index) in conditions" :key="index">
        <SegmentationConditions
          :key="condition.filters[0].dataType + index"
          :first-data-type="condition.filters[0].dataType"
          :condition="condition"
          :index="index"
          :last="index === conditions.length - 1"
          @updateCondition="updateCondition"
          @removeCondition="removeCondition"
        />
        <button
          v-if="shouldShowLogicToggle(index)"
          class="logic-toggle"
          @click="toggleLogic"
        >
          {{ globalOperator }}
        </button>
      </div>
    </div>

    <div class="button-container">
      <button
        v-if="!conditions || conditions.length < 1"
        class="add-segmentation"
        @click="addCondition"
      >
        {{ $t("advancedSegmentation.addRule") }}
      </button>
      <button
        v-if="shouldShowAnotherRuleButton"
        class="add-segmentation"
        @click="addCondition"
      >
        {{ $t("advancedSegmentation.anotherRule") }}
      </button>
      <SegmentationDataTypeModal
        v-if="displayDataTypeModal"
        :is-visible="displayDataTypeModal"
        :is-advanced-segmentation="true"
        @select="onDataTypeSelected"
        @close="closeDataTypeModal"
        @cancel="closeDataTypeModalAndRemoveCondition"
      />

      <SegmentationFilterDropdown
        v-if="displayFilterDropdown"
        :is-new-advanced-rule="isNewAdvancedRule"
        :is-advanced-segmentation="true"
        :filter="lastCondition.filters[0]"
        @close="confirmRule"
      />
    </div>
    <AddToCartModal
      v-if="showProductsModal"
      :is-full-screen="true"
      :products-loading="productsLoading"
      :is-advanced-segmentation="true"
      @close-add-to-cart="closeAddToCartModal"
      @products-to-advanced-segmentation="submitAddToCartProducts"
    />
  </div>
</template>

<script>
import SegmentationConditions from "@/components/shared/SegmentationConditions.vue";
import SegmentationFilterDropdown from "@/components/shared/SegmentationFilterDropdown.vue";
import SegmentationDataTypeModal from "@/components/shared/SegmentationDataTypeModal.vue";
import AddToCartModal from "@/components/create-campaign-steps/step3/AddToCartModal.vue";

export default {
  components: {
    SegmentationConditions,
    SegmentationFilterDropdown,
    SegmentationDataTypeModal,
    AddToCartModal,
  },
  props: {
    segmentation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      conditions: [],
      displayFilterDropdown: false,
      displayDataTypeModal: false,
      newConditionDataType: "",
      globalOperator: "and",
      isNewAdvancedRule: false,
      showProductsModal: false,
      productsLoading: false,
    };
  },
  computed: {
    lastCondition() {
      return this.conditions[this.conditions.length - 1] || {};
    },
    shouldShowAnotherRuleButton() {
      return (
        this.conditions.length >= 1 && this.conditions[0].filters[0]?.dataType
      );
    },
  },
  watch: {
    conditions: {
      handler() {
        this.$emit("update-conditions", this.formatAdvancedConditions());
      },
      deep: true,
    },
  },
  created() {
    const conditions = [];

    if (this.segmentation.advanced) {
      const advanced = this.segmentation.advanced;

      advanced.forEach((segmentation) => {
        if (segmentation.comparison && segmentation.dataType) {
          let newCondition;
          if (segmentation.dataType === "products") {
            newCondition = {
              filters: [
                {
                  comparison: segmentation.comparison,
                  dataType: segmentation.dataType,
                  productId: segmentation.productId,
                  isEditing: false,
                },
              ],
              type: segmentation.type || "and",
            };
          } else {
            newCondition = {
              filters: [
                {
                  comparison: segmentation.comparison,
                  string: segmentation.string || "",
                  dataType: segmentation.dataType || "",
                  isEditing: false,
                },
              ],
              type: segmentation.type || "and",
            };
          }
          conditions.push(newCondition);
          return;
        }

        if (segmentation.isSameLine) {
          const filtersWithEditing = segmentation.filters.map((filter) => ({
            ...filter,
            isEditing: false,
          }));
          conditions.push({
            type: segmentation.type || "and",
            filters: filtersWithEditing,
          });
          return;
        }

        segmentation.filters.forEach((filterGroup) => {
          const newFilterGroup = {
            filters: [],
            type: filterGroup.type || "and",
          };

          if (Array.isArray(filterGroup.filters)) {
            filterGroup.filters.forEach((filter) => {
              if (filter.dataType === "products") {
                newFilterGroup.filters.push({
                  comparison: filter.comparison,
                  dataType: "products",
                  isEditing: false,
                  productId: filter.productId || [],
                });
              } else {
                newFilterGroup.filters.push({
                  comparison: filter.comparison,
                  dataType: filter.dataType,
                  string: filter.string || "",
                  isEditing: false,
                });
              }
            });
          } else {
            if (filterGroup.dataType === "products") {
              newFilterGroup.filters.push({
                comparison: filterGroup.comparison,
                dataType: "products",
                isEditing: false,
                productId: filterGroup.productId || [],
              });
            } else {
              newFilterGroup.filters.push({
                comparison: filterGroup.comparison,
                dataType: filterGroup.dataType,
                isEditing: false,
                string: filterGroup.string || "",
              });
            }
          }
          conditions.push(newFilterGroup);
        });
      });

      if (conditions.length > 0) {
        this.conditions = conditions;
      }
    }
  },
  methods: {
    addCondition() {
      if (this.displayDataTypeModal || this.displayFilterDropdown) {
        return;
      }

      this.conditions.push({
        filters: [
          { comparison: "eq", isEditing: false, string: "", dataType: "" },
        ],
        type: "and",
      });

      this.displayDataTypeModal = true;
    },
    onDataTypeSelected(dataType) {
      if (dataType === "products") {
        this.showProductsModal = true;
        return;
      }
      this.lastCondition.filters[0].dataType = dataType;

      this.displayDataTypeModal = false;
      this.displayFilterDropdown = true;
    },
    closeDataTypeModal() {
      this.displayDataTypeModal = false;
    },
    closeDataTypeModalAndRemoveCondition() {
      this.conditions.pop();
      this.displayDataTypeModal = false;
    },
    confirmRule() {
      this.displayFilterDropdown = false;
      this.isNewAdvancedRule = false;
    },
    submitAddToCartProducts(payload, comparison) {
      const productIds = payload.map((product) => product.productId);

      this.conditions.pop();

      this.conditions.push({
        filters: [
          {
            comparison: comparison,
            isEditing: false,
            productId: productIds,
            dataType: "products",
          },
        ],
        type: "and",
      });
      this.showProductsModal = false;
    },
    updateCondition(index, newCondition) {
      this.conditions.splice(index, 1, newCondition);
    },
    removeCondition(index) {
      this.conditions.splice(index, 1);
    },
    closeAddToCartModal() {
      if (
        this.conditions.length > 0 &&
        !this.conditions[this.conditions.length - 1]?.dataType
      ) {
        this.conditions.pop();
      }

      this.showProductsModal = false;
    },
    shouldShowLogicToggle(index) {
      return (
        index < this.conditions.length - 1 &&
        this.conditions
          .slice(0, index)
          .every(
            (condition) =>
              condition.filters[0]?.string || condition.filters[0]?.productId
          )
      );
    },
    toggleLogic() {
      this.globalOperator = this.globalOperator === "and" ? "or" : "and";
    },
    formatAdvancedConditions() {
      const advanced = [];

      if (
        this.conditions.length === 1 &&
        this.conditions[0].filters.length === 1
      ) {
        const filterWithoutEditing = { ...this.conditions[0].filters[0] };
        delete filterWithoutEditing.isEditing;

        advanced.push(filterWithoutEditing);
        return {
          segmentation: {
            ...this.segmentation,
            advanced: advanced,
          },
        };
      }

      if (this.conditions.length === 1) {
        advanced.push({
          isSameLine: true,
          type: this.conditions[0].type,
          filters: this.conditions[0].filters.map((filter) => {
            const filterWithoutEditing = { ...filter };
            delete filterWithoutEditing.isEditing;
            return filterWithoutEditing;
          }),
        });
        return {
          segmentation: {
            ...this.segmentation,
            advanced: advanced,
          },
        };
      }

      const mainFilter = {
        isSameLine: false,
        type: this.globalOperator,
        filters: [],
      };

      this.conditions.forEach((condition) => {
        if (condition.filters?.length === 1) {
          const filterWithoutEditing = { ...condition.filters[0] };
          delete filterWithoutEditing.isEditing;
          mainFilter.filters.push(filterWithoutEditing);
          return;
        }
        mainFilter.filters.push({
          type: condition.type,
          filters: condition.filters.map((filter) => {
            const filterWithoutEditing = { ...filter };
            delete filterWithoutEditing.isEditing;
            return filterWithoutEditing;
          }),
        });
      });

      advanced.push(mainFilter);

      return {
        segmentation: {
          ...this.segmentation,
          advanced: advanced,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  position: relative;
}

.add-segmentation {
  @include base-font;
  @include font-smaller;
  color: $dark-purple;
  background-color: transparent;
  border: none;
  margin-top: 14px;
  font-weight: 600;
}

select {
  margin-top: 10px;
}

.logic-toggle {
  @include base-font;
  @include font-smaller;
  margin-bottom: 10px;
  height: 32px;
  border-radius: 2px;
}

.where-to-show {
  @include base-font;
  @include font-normal;
  margin-bottom: 12px;
}
</style>
