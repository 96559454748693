var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edit-footer"},[_c('button',{class:[
      'cancel-button flex-button',
      { 'small-font': _vm.isSmallFontOnMobile, disabled: _vm.disabledPrev },
    ],attrs:{"disabled":_vm.disabledPrev},on:{"click":_vm.handleClickPrev}},[_vm._v(" "+_vm._s(_vm.$t("create.bulk.modalButtons.leave"))+" "),_c('SmallSquaredX',{staticClass:"button-icon"})],1),_c('button',{class:[
      'save-button flex-button',
      { 'small-font': _vm.isSmallFontOnMobile, disabled: _vm.disabledNext },
    ],attrs:{"disabled":_vm.disabledNext},on:{"click":_vm.handleClickNext}},[_vm._v(" "+_vm._s(_vm.$t("create.bulk.modalButtons.save"))+" "),_c('SquaredCheckIcon',{staticClass:"button-icon"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }