<template>
  <sliding-modal
    class="create-product-sliding-modal"
    :display-close-button="false"
    @close="close"
  >
    <div class="create-product-wrapper">
      <div class="header">
        <h1 class="title">{{ $t("productPages.createProductModal.title") }}</h1>
        <h2 class="subtitle">
          {{ $t("productPages.createProductModal.subtitle") }}
        </h2>
      </div>
      <div class="product-form">
        <div class="first-row">
          <div class="product-title-block block">
            <label for="product-title" class="product-title-label label">{{
              $t("productPages.createProductModal.labels.title")
            }}</label>
            <input
              id="product-title"
              v-model="productData.name"
              class="product-title-input input"
              type="text"
            />
          </div>
          <div class="price-currency">
            <div class="product-price-block block">
              <label for="product-price" class="product-price-label label">{{
                $t("productPages.createProductModal.labels.price")
              }}</label>
              <input
                id="product-price"
                v-model.number="productData.variants[0].price"
                type="number"
                class="product-price-input input"
              />
            </div>
            <v-select
              v-model="selectedCurrency"
              class="currency-dropdown"
              :options="currencyOptions"
            ></v-select>
          </div>
        </div>
        <div class="second-row">
          <div class="product-url-block block">
            <label for="product-url" class="product-url-label label">{{
              $t("productPages.createProductModal.labels.url")
            }}</label>
            <input
              id="product-url"
              v-model="productData.productUrl"
              class="product-url-input input"
              type="text"
            />
          </div>
        </div>
        <div class="third-row">
          <div class="product-description-block block">
            <label
              for="product-description"
              class="product-description-label label"
              >{{
                $t("productPages.createProductModal.labels.description")
              }}</label
            >
            <textarea
              id="product-description"
              v-model="productData.description"
              class="product-description-input input"
              rows="4"
            >
            </textarea>
          </div>
        </div>
        <div class="fourth-row">
          <div class="product-images-block block">
            <label for="product-images" class="product-images-label label">{{
              $t("productPages.createProductModal.labels.images")
            }}</label>
            <div
              v-for="(image, index) in productData.imageUrls"
              :key="index"
              class="input-field-loading"
            >
              <input
                id="product-images"
                v-model="productData.imageUrls[index]"
                class="product-images-input input"
                type="text"
              />
              <div
                v-if="productData.imageUrls[index].trim() !== ''"
                class="icon-container"
              >
                <div v-if="isImageInvalid(index)" class="loading-icon">
                  <ClearIcon />
                </div>
                <div v-else>
                  <CircleCheckedIcon class="checked-icon-input" />
                </div>
              </div>
            </div>
            <button
              v-if="areAllImagesFilled()"
              class="import-another-image-button"
              @click="addImageInput"
            >
              {{ $t("productPages.createProductModal.labels.import") }}
            </button>
          </div>
          <div class="draggable-and-arrows">
            <draggable
              v-model="productData.imageUrls"
              class="images-wrapper"
              v-bind="{ handle: '.drag-block' }"
              :style="{ '--item-count': productData.imageUrls.length }"
            >
              <div
                v-for="(image, index) in productData.imageUrls"
                :key="index"
                class="image-item"
              >
                <div
                  v-if="
                    productData.imageUrls[index] !== undefined &&
                    productData.imageUrls[index].trim() !== ''
                  "
                >
                  <div
                    class="main-img-container"
                    :style="`background-image: url('${image}');`"
                  ></div>
                  <div class="actions">
                    <div class="drag-block">
                      <DragDropIcon />
                      <p class="image-index">{{ index + 1 }}</p>
                    </div>
                    <button class="delete-button" @click="deleteImage(index)">
                      <DeleteIcon class="trash-icon" />
                    </button>
                  </div>
                </div>
              </div>
            </draggable>
            <div
              v-if="productData.imageUrls[0].trim() !== ''"
              class="btn-group"
            >
              <button class="btn" @click="scrollLeft">
                <BtnArrowBack />
              </button>
              <button class="btn" @click="scrollRight">
                <BtnArrowNext />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="cancel-button flex-button" @click="close">
          {{ $t("create.bulk.modalButtons.leave") }}
          <SmallSquaredX class="button-icon" />
        </button>
        <button class="save-button flex-button" @click="createOrEditProduct">
          {{ $t("create.bulk.modalButtons.save")
          }}<SquaredCheckIcon class="button-icon" />
        </button>
      </div>
    </div>
  </sliding-modal>
</template>

<script>
import SlidingModal from "@/components/shared/SlidingModal.vue";
import CircleCheckedIcon from "@/assets/svg/dashboard/circle_checked.svg?inline";
import DragDropIcon from "@/assets/svg/drag-drop-icon-small.svg?inline";
import Draggable from "vuedraggable";
import DeleteIcon from "@/assets/svg/delete-small.svg?inline";
import BtnArrowNext from "@/assets/svg/btn-arrow-next.svg?inline";
import BtnArrowBack from "@/assets/svg/btn-arrow-back.svg?inline";
import ClearIcon from "@/assets/svg/clear-icon.svg?inline";
import SquaredCheckIcon from "@/assets/svg/squared-check-icon.svg?inline";
import SmallSquaredX from "@/assets/svg/small-squared-x-icon.svg?inline";

import { mapGetters, mapState } from "vuex";

export default {
  components: {
    SlidingModal,
    CircleCheckedIcon,
    DragDropIcon,
    Draggable,
    DeleteIcon,
    BtnArrowNext,
    BtnArrowBack,
    ClearIcon,
    SquaredCheckIcon,
    SmallSquaredX,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    editProductData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currencyOptions: ["EUR", "AUD", "BRL", "CAD", "DKK", "USD"],
      productData: {
        siteId: "",
        name: "",
        imageUrl: "",
        imageUrls: [""],
        description: "",
        productUrl: "",
        variants: [
          {
            name: "",
            price: null,
            imageUrl: "",
          },
        ],
        options: [
          {
            name: "title",
            position: 1,
            values: [],
          },
        ],
        status: "active",
        isCreatedManually: true,
      },
      selectedCurrency: "",
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      siteId: "account/getSiteId",
      currency: "site/getRawCurrency",
      getCurrency: "site/getCurrency",
    }),
    isImageInvalid() {
      return (index) => {
        const url = this.productData.imageUrls[index].trim();
        const imageFileExtensions = /\.(jpeg|jpg|png|gif)(\?.*)?$/;

        return (
          url === "" ||
          !url.startsWith("http") ||
          !imageFileExtensions.test(url)
        );
      };
    },
    defaultCurrency: {
      get() {
        return this.currency || this.selectedCurrency;
      },
      set(value) {
        this.selectedCurrency = value;
      },
    },
  },
  watch: {
    editProductData(newEditProductData) {
      if (this.editMode && newEditProductData) {
        this.productData = newEditProductData;
      }
    },
    defaultCurrency(newDefaultCurrency) {
      this.selectedCurrency = newDefaultCurrency;
    },
  },
  async created() {
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });
    this.productData.siteId = this.siteId;
    if (this.editMode && this.editProductData) {
      this.productData = this.editProductData;
    }
    if (this.currency && this.currencyOptions.includes(this.currency)) {
      this.selectedCurrency = this.currency;
    } else {
      this.selectedCurrency = "";
    }
  },
  methods: {
    close() {
      this.$emit("close-elements-modal");
      this.resetProductData();
    },
    async createOrEditProduct() {
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

      // Check if productUrl is a valid URL
      if (!urlRegex.test(this.productData.productUrl)) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t(
            "productPages.createProductModal.toastMessage.validUrl"
          ),
          type: "error",
        });
        return;
      }

      // Check if imageUrls are image links
      const invalidImage = await this.findInvalidImage();
      if (invalidImage) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t(
            "productPages.createProductModal.toastMessage.validImage"
          ),
          type: "error",
        });
        return;
      }

      //Check if all necessary fields are filled
      if (
        !this.productData.name ||
        this.productData.variants.length === 0 ||
        !this.productData.variants[0].price ||
        !this.productData.productUrl ||
        this.productData.imageUrls.length === 0 ||
        this.productData.imageUrls[0].trim() === ""
      ) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("productPages.createProductModal.toastMessage.fill"),
          type: "error",
        });
        return;
      }

      //Add additional necessary data
      this.productData.imageUrl = this.productData.imageUrls[0];
      this.productData.variants[0].name = this.productData.name;
      this.productData.variants[0].imageUrl = this.productData.imageUrls[0];
      this.productData.options[0].values[0] = this.productData.name;

      //We don't send description to BE if is empty
      if (this.productData.description === "") {
        delete this.productData.description;
      }

      //Update product if we are on edit mode
      if (this.editMode) {
        try {
          await this.$store.dispatch("product/updateProduct", {
            productId: this.productData._id,
            productData: this.productData,
          });

          this.$notify({
            title: this.$t("shared.toastMessage.success"),
            text: this.$t(
              "productPages.createProductModal.toastMessage.updated"
            ),
            type: "success",
          });
        } catch (error) {
          console.error("Error updating product:", error);
        }

        //Create product
      } else {
        try {
          await this.$store.dispatch("product/createProduct", this.productData);
          this.$notify({
            title: this.$t("shared.toastMessage.success"),
            text: this.$t(
              "productPages.createProductModal.toastMessage.created"
            ),
            type: "success",
          });
        } catch (error) {
          console.error("Error creating product:", error);
        }

        //Update currency if selected currency is different than currency
      }
      if (this.selectedCurrency !== this.currency) {
        await this.$store.dispatch("site/updateSite", {
          siteId: this.site.site._id,
          dataToUpdate: {
            currency: this.selectedCurrency,
          },
        });
      }
      this.resetProductData();
      this.close();
    },
    deleteImage(index) {
      if (
        this.productData.imageUrls.length > 0 &&
        index >= 0 &&
        index < this.productData.imageUrls.length
      ) {
        this.productData.imageUrls.splice(index, 1);

        if (this.productData.imageUrls.length === 0) {
          this.productData.imageUrls.push("");
        }
      }
    },
    addImageInput() {
      this.productData.imageUrls.push("");
    },
    async findInvalidImage() {
      const imageFileExtensions = /\.(jpeg|jpg|png|gif)$/;

      for (const url of this.productData.imageUrls) {
        if (!imageFileExtensions.test(url)) {
          try {
            await this.loadImage(url);
          } catch (error) {
            return url;
          }
        }
      }
      return null;
    },
    loadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = url;
      });
    },
    scrollLeft() {
      const content = document.querySelector(".images-wrapper");
      const scrollAmount = 150;
      content.scrollLeft -= scrollAmount;
    },

    scrollRight() {
      const content = document.querySelector(".images-wrapper");
      const scrollAmount = 150;
      content.scrollLeft += scrollAmount;
    },
    areAllImagesFilled() {
      return this.productData.imageUrls.every((url) => url.trim() !== "");
    },
    resetProductData() {
      const defaultProductData = {
        siteId: "",
        name: "",
        imageUrl: "",
        imageUrls: [""],
        description: "",
        productUrl: "",
        variants: [
          {
            name: "",
            price: null,
            imageUrl: "",
          },
        ],
        options: [
          {
            name: "title",
            position: 1,
            values: [],
          },
        ],
        status: "active",
        isCreatedManually: true,
      };
      this.productData = defaultProductData;
    },
  },
};
</script>

<style lang="scss">
.create-product-sliding-modal .sliding-modal {
  width: 90%;
}

.currency-dropdown {
  align-self: flex-end !important;
  .v-select {
    position: relative;
  }
  .vs__dropdown-toggle {
    width: 100px;
    height: 30px;
    background-color: rgba(196, 196, 196, 0.3);
  }
  .vs__dropdown-menu {
    position: absolute !important;
    z-index: 1000;
    width: 100px !important;
    min-width: 100px !important;
  }
  .vs__dropdown-option {
    padding: 8px;
  }
}
</style>

<style lang="scss" scoped>
.create-product-wrapper {
  padding: 0 80px;
  overflow: auto;
}

.create-product-wrapper::-webkit-scrollbar {
  width: 0;
}

.create-product-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.create-product-wrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 48px;
}
.title {
  @include base-font;
  @include font-big;
  font-weight: 600;
}

.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
}

.product-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  margin: 0 auto 120px;
  width: 100%;
}

.product-form::-webkit-scrollbar {
  width: 0;
}

.product-form::-webkit-scrollbar-track {
  background-color: transparent;
}

.product-form::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.label {
  @include base-font;
  @include font-small;
  font-weight: 500;
}

.input {
  @include base-font;
  height: 30px;
  border-radius: 2px;
  border: 1px solid $light-grey;
  padding: 5px 10px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px $pink;
  }
}

.first-row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 40px;
}

.product-title-block {
  width: calc(70% - 20px);
  .product-title-input {
    width: 100%;
  }
}

.price-currency {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.product-price-block {
  width: calc(30% - 20px);
  .product-price-input {
    width: 150px;
  }
}

.second-row {
  width: 100%;
}

.product-url-block {
  .product-url-input {
    width: 100%;
  }
}

.product-description-block {
  .product-description-input {
    width: 100%;
    height: 120px;
    resize: none;
  }
}

.fourth-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 40px;
}

.product-images-block {
  width: calc(40% - 20px);
  .product-images-input {
    min-width: 320px;
  }
}

.input-field-loading {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 16px;
  gap: 8px;
}
.flex-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checked-icon-button {
  path {
    fill: $dark-grey;
  }
}

.checked-icon-input {
  margin-left: 4px;
  transform: scale(1.71429);
  path {
    fill: $dark-purple;
  }
}

.import-another-image-button {
  background: none;
  @include base-font;
  @include font-smaller;
  font-weight: 600;
  color: $dark-purple;
  margin-bottom: 40px;
}

.images-wrapper {
  display: inline-flex;
  overflow: hidden;
  gap: 12px;
  max-width: 100%;
  scroll-behavior: smooth;
  margin-bottom: 16px;
  padding: 6px;
}

.image-item {
  height: 125px;
  display: grid;
  background-color: white;
  border-radius: 3px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12);
  justify-items: center;
  &:hover {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  }
}

.draggable-and-arrows {
  width: calc(60% - 20px);
}

.main-img-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
  text-align: center;
  cursor: pointer;
  height: 58px;
  width: 113px;
  border-radius: 2px;
  margin: 8px 8px 2px 8px;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.drag-block {
  font-family: "Yeon Sung", "sans-serif";
  font-size: 24px;
  color: #757575;
  display: flex;
  align-items: center;
  margin: 4px;
  margin-left: 9px;
  gap: 5px;
  cursor: pointer;
}

.delete-button {
  cursor: pointer;
  background-color: white;
  padding: 6px 9px;
  max-width: 100px;
  border-radius: 2px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  margin: 4px;
  margin-right: 9px;
  &:hover {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  }
}
.trash-icon {
  transform: scale(150%);
  path {
    fill: $dark-grey;
    fill-opacity: 1;
  }
}
.image-index {
  font-family: "Yeon Sung", "sans-serif";
  font-size: 24px;
  color: #757575;
  text-align: start;
  max-width: 52px;
  border: 0;
  background: transparent;
}
.btn-group {
  display: flex;
  width: 30%;
  margin: 0 auto;
  justify-content: center;
}
.btn {
  background: transparent;
}
.flex-button {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
